/*-----Основной контейнер---------------------------------------------------------------------------------------------*/
.my-profile-page-add-settings__wrapper {
    display: flex;
    flex-direction: column;
    padding: 32px 32px 40px 32px;
    box-shadow: 0 6px 16px 0 rgba(54, 58, 72, 0.07);
    border-radius: 24px;
    max-height: 304px;
    background: #FFFFFF;
    /*max-width: 656px;*/
}
.my-profile-page-add-settings__wrapper h3 {
    font-size: 24px;
    font-weight: 600;
    line-height: 110%;
    color: rgba(54, 59, 77, 0.9);
    padding-bottom: 32px;
}
/*-----Стилизация для формы--------------------------------------------------------------------------------------------*/
.my-profile-page-add-settings__form {
    display: flex;
    flex-direction: column;
    color: rgba(54, 59, 77, 0.9);
    width: 100%;
    padding-bottom: 32px;
    position: relative;
}
.my-profile-page-add-settings__form label {
    font-size: 18px;
    color: rgba(54, 59, 77, 0.9);
    line-height: 24px;
    font-weight: 400;
}
/*------Форма выбора часового пояса-------------------------------------------------------------------------------------*/
/* Переделал */
.my-profile-form__time-zone-select-container {
    display: flex;
    position: relative;
    width: 100%;
    height: 48px;
    margin: 16px auto 0 0;
    border: 0.5px solid rgba(54, 59, 77, 0.3);
    box-sizing: border-box;
    border-radius: 8px;
    cursor: pointer;
}
.my-profile-form__time-zone-select-value {
    font-size: 16px;
    line-height: 22px;
    color: rgba(54, 59, 77, 0.9);
    opacity: 0.9;
    margin: auto auto auto 24px;
    max-width: 250px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
.my-profile-form__time-zone-select-arrow {
    width: 16px;
    height: 16px;
    object-fit: cover;
    margin: auto 24px auto auto;
}
.my-profile-form__time-zone-options-container {
    display: flex;
    flex-direction: column;
    position: absolute;
    background: #FFFFFF;
    overflow-y: scroll;
    top: 58px;
    left: -1px;
    width: 100%;
    max-height: 200px;
    box-shadow: 0 4px 8px rgba(54, 59, 77, 0.1);
    border: 0.5px solid rgba(54, 59, 77, 0.2);
    border-radius: 4px;
    box-sizing: border-box;
    padding: 0 24px 24px;
    cursor: default;
    z-index: 9;
}
.my-profile-form__time-zone-options-container::-webkit-scrollbar {
    width: 4px;
    background: #FFFFFF;
    border-radius: 4px;
}
.my-profile-form__time-zone-options-container::-webkit-scrollbar-thumb {
    background: #CFD4DD;
    border-radius: 4px;
}
.my-profile-form__time-zone-option {
    line-height: 24px;
    color: #363B4D;
    opacity: 0.9;
    margin: 24px auto 0 0;
}
.my-profile-form__time-zone-option:hover {
    cursor: pointer;
    opacity: 0.6;
}
/*-----Cтилизация кнопочки---------------------------------------------------------------------------------------------*/
.my-profile-page__save-change {
    max-width: 224px;
    padding: 12px 24px 12px 24px;
    background: rgba(54, 59, 77, 0.08);
    border-radius: 4px;
    font-size: 16px;
    font-weight: 500;
    line-height: 124%;
    color: rgba(54, 59, 77, 0.35);
    height: 48px;
}
/*------Сообщение об ошибки данных полей------------------------------------------------------------------------------*/
.my-profile-page-add-settings__message {
    color: #4ED4A9;
    font-size: 12px;
    position: absolute;
    bottom: 8px;
}

/*------Адаптивная версия---------------------------------------------------------------------------------------------*/
@media (max-width: 1024px) {
    .my-profile-page-add-settings__wrapper {
        padding: 16px 16px 24px 16px;

    }
    .my-profile-page-add-settings__wrapper h3 {
        font-size: 21px;
        font-weight: 500;
        line-height: 138%;
        color: rgba(54, 59, 77, 0.9);
        padding-bottom: 16px;
    }
    .my-profile-page-add-settings__form label {
        font-size: 16px;
        line-height: 22px;
    }
    .my-profile-page-add-settings__form {
        gap: 6px;
        padding-bottom: 24px;
    }
    .my-profile-page__save-change  {
        height: 40px;
        font-size: 14px;
    }
}
@media (max-width: 420px) {
    .my-profile-page__save-change  {
        max-width: 100%;
    }
}
