/*------Основной контейнер--------------------------------------------------------------------------------------------*/
.details-votes-page-result-votes__wrapper {
    display: flex;
    flex-direction: column;
    gap: 32px;
    padding-bottom: 112px;
}
/*-----Стилизация для основного контента------------------------------------------------------------------------------*/
.details-votes-page-result-votes__main-content {
    display: flex;
    flex-direction: column;
    gap: 24px;
    padding: 32px 32px 40px 32px;
    box-shadow: 0 3px 16px 0 rgba(54, 58, 72, 0.08);
    border-radius: 24px;
    background-color: #FFFFFF;
}
.details-votes-page-result-votes__title {
    font-size: 20px;
    color: rgba(54, 59, 77, 0.9);
    font-weight: 600;
    line-height: 110%
}
/*-----Стилизация кнопок-переключателей--------------- ---------------------------------------------------------------*/
.results-page-switch-buttons {
    display: flex;
    font-size: 20px;
    font-weight: 500;
    line-height: 32px;
    color: rgba(54, 59, 77, 0.9);
    border-bottom: 1px #EEEEEE solid;
}
.results-page-switch-buttons__button {
    padding-bottom: 8px;
    border-bottom: 2px #FFFFFF solid;
    margin-right: 48px;
    cursor: pointer;
}
.results-page-switch-buttons__button:hover {
    border-bottom: 2px #0084FE solid;
}
.active-results-page-switch-buttons__button {
    padding-bottom: 8px;
    border-bottom: 2px #0084FE solid;
    margin-right: 48px;
    cursor: pointer;
}
._mobile-read-questions-bnt {
    display: none;
}
._read-questions-bnt {
    display: initial;
}
/*-----Адаптивная версия---------------------------------------------------------------------------------------------*/
@media (max-width: 1024px) {
    .results-page-switch-buttons {
        font-size: 16px;
        font-weight: 500;
        line-height: 114%;
        white-space: nowrap;
    }
}
@media (max-width: 992px) {
    .results-page-switch-buttons {
        overflow: hidden;
        text-overflow: inherit;
    }
    .details-votes-page-result-votes__title {
        font-size: 16px;
    }
}

@media (max-width: 992px) {
    .details-votes-page-result-votes__main-content {
        gap: 16px;
    }
    .details-votes-page-result-votes__title {
        font-size: 20px;
    }
}
@media (max-width: 767px) {
    .details-votes-page-result-votes__title {
        display: none;
    }
    .details-votes-page-result-votes__wrapper {
        gap: 8px;
    }
    .details-votes-page-result-votes__main-content {
        padding: 16px 16px;
    }
}
@media (max-width: 767px) {
    ._mobile-read-questions-bnt {
        display: inherit;
    }
    ._read-questions-bnt {
        display: none;
    }
}
@media (max-width: 420px) {
    .results-page-switch-buttons {
        border-bottom: 2px #FFFFFF solid;
        overflow: auto;
    }
    .results-page-switch-buttons__button {
        margin-right: 24px;
    }
    .active-results-page-switch-buttons__button {
        margin-right: 24px;
    }
    .results-page-switch-buttons__button:hover {
        border-bottom: 2px #0084FE solid;
        cursor: pointer;
    }
}

.statistics__header {
    display: flex;
    align-items: baseline;
}

.statistics__info {
    font-size: 14px;
    color: #363B4D;
    opacity: 0.4;
}