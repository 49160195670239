/*------Модификатор заголовка Установка пароля-------------------------------------------------------------------------*/
._modificator-auth__title-padding-bottom {
    padding-bottom: 48px;
}
/*------Модификатор блока с формой для паролей-------------------------------------------------------------------------*/
._modificator-main-block__auth-set-pass-padding {
    padding: 70px 56px 70px 56px;
}
/*------Модификатор заголовка в блоке с логотипом----------------------------------------------------------------------*/
._modificator-reg__title-set-pass-top {
    top: 420px;
}
/*-----Стилизация блока с input password --------------------------------------------------------------------------------*/
.form__password {
    display: flex;
    flex-direction: column;
    position: relative;
    padding-bottom: 60px;
    gap: 20px;
}
/*------Стилизация поля ввода password---------------------------------------------------------------------------------*/
.form__password input {
    border-radius: 8px;
    opacity: 50%;
    width: 100%;
    height: 56px;
    padding: 5px 0 5px 10px;
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
    outline: none;
    border: 1px solid rgba(54, 59, 77, 0.6);
}
.form__password-error {
    font-size: 12px;
    font-weight: 400;
    color: #FF4970;
    opacity: 0.9;
    margin: 0 0 0 auto;
    position: absolute;
    bottom: 35px;
}
/*------Стилизация иконки скрыть/показать пароль-----------------------------------------------------------------------*/
.form__password__show-pass-icon {
    position: absolute;
    top: 58px;
    left: 610px;
    z-index: 5;
    cursor: pointer;
    width: 22px;
    height: 20px;
}
/*------Стилизация кнопки Сохранить-----------------------------------------------------------------------*/
.auth__button-save button {
    width: 175px;
    height: 56px;
    border-radius: 4px;
    color: #FFFFFF;
    font-size: 18px;
    font-weight: 500;
    line-height: 133%;
    background-color: #0084FE;
    cursor: pointer;
}
.auth__button-save button:hover {
    background-color: #FFFFFF;
    color: #0084FE;
    border: 1px #0084FE solid;
}


/*------Адаптивная версия(1100px)--------------------------------------------------------------------------------------*/
@media (max-width: 1280px) {
    .form__password__show-pass-icon {
        top: 55px;

    }
}
@media (max-width: 1280px) {
    .form__password__show-pass-icon {
        top: 55px;
        left: 400px;
    }
}
/*------Адаптивная версия(800px)--------------------------------------------------------------------------------------*/
@media (max-width: 992px) {
    ._modificator-main-block__auth-set-pass-padding {
        padding: 24px 16px 24px 16px;
        top: 314px;
    }
    ._modificator-auth__title-padding-bottom {
        padding-bottom: 24px;
    }
    .auth__button-save button {
        width: 100%;
        height: 48px;
        z-index: 5;
    }
    .form__password {
        padding-bottom: 48px;
        gap: 8px;
    }
    .form__password input {
        width: 100%;
        height: 48px;
    }
    .form__password__show-pass-icon {
        top: 36px;
        left: 320px;
    }
    .main-block__auth.active {
        z-index: -5;
    }
    .form__password-error {
        font-size: 10px;
        bottom: 7px;
    }
}
/*------Адаптивная версия(375px)--------------------------------------------------------------------------------------*/
@media (max-width: 767px) {
    .form__password {
        padding-bottom: 24px;
        gap: 8px;
    }
    .form__password__show-pass-icon  {
        top: 40px;
        left: 300px;
    }
    .form__password-error {
        font-size: 8px;
        bottom: 7px;
    }
}
