.calendar-votes-timetable-list__wrapper {
    display: grid;
    grid-template-columns: 200px 1fr;
    grid-template-rows: auto;
    border-bottom: none;
    padding: 12px 0 14px 0;
    border-top: 1px solid rgba(54, 59, 77, 0.3);
}
.calendar-votes-timetable-list__time-and-event {
    display: flex;
    flex-direction: column;
    height: 50%;

}
.calendar-votes-timetable-list__time-vote {
    font-size: 16px;
    color: rgba(54, 59, 77, 0.9);
    font-weight: 400;
    line-height: 24px;
}
.calendar-votes-timetable-list__event-reg-vote {
    font-size: 12px;
    line-height: 25px;
    width: 140px;
}
.calendar-votes-timetable-list__voting-theme {
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
    color: rgba(54, 59, 77, 0.9);
}
.event-reg-vote__circle-event-name {
    position: relative;
    min-width: 180px;
    white-space: nowrap;
    display: flex;
    flex-direction: column;
}
/*------Блок для синего кружка---------------------------------------------------------------------------------------*/
.circle-event-name__start-reg {
    position: relative;
    padding-left: 16px;
}
.color__circle {
    width: 8px;
    height: 8px;
    border-radius: 100%;
    position: absolute;
    right: 172px;
    top: 9px;
}

/*------Блок для оранжевого кружка---------------------------------------------------------------------------------------*/
.circle-event-name__end-reg {
    position: relative;
    padding-left: 16px;
}

.color__circle-end-reg {
    width: 8px;
    height: 8px;
    border-radius: 100%;
    position: absolute;
    right: 172px;
    top: 9px;
}
/*------Блок для зеленого кружка---------------------------------------------------------------------------------------*/
.circle-event-name__start-vote {
    position: relative;
    padding-left: 16px;
}
.color__circle-start-vote {
    width: 8px;
    height: 8px;
    border-radius: 100%;
    position: absolute;
    right: 172px;
    top: 9px;
}
/*------Блок для красного кружка---------------------------------------------------------------------------------------*/
.circle-event-name__end-vote {
    position: relative;
    padding-left: 16px;
}
.color__circle-end-vote {
    width: 8px;
    height: 8px;
    border-radius: 100%;
    position: absolute;
    right: 172px;
    top: 9px;
}

/*------Адаптивная версия----------------------------------------------------------------------------------------------*/
@media (max-width: 1280px) {
    .calendar-votes-timetable-list__wrapper {
        padding: 12px 0 12px 0;
        grid-template-columns: 170px 1fr;
    }
    .calendar-votes-timetable-list__voting-theme {
        font-size: 16px;
        line-height: 22px;
    }
}
@media (max-width: 992px) {
    .calendar-votes-timetable-list__wrapper {
        grid-template-columns: 150px 1fr;
    }
    .calendar-votes-timetable-list__voting-theme {
        font-size: 14px;
        line-height: 22px;
    }

}

@media (max-width: 992px) {
    .calendar-votes-timetable-list__event-reg-vote {
        font-size: 12px;
        line-height: 27px;
        width: 140px;
    }
    .event-reg-vote__circle-event-name {

    }
}