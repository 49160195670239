.registration-pass-require-modal__wrapper {
    z-index: 18;
    position: absolute;
    top: 84px;
    left: 0;
    opacity: 0;
    pointer-events: none;
}
.registration-pass-require-modal__wrapper.active {
    opacity: 1;
    transform: scale(1);
    pointer-events: all;
}
.registration-pass-require-modal__content {
    position: relative;
    display: inline-block;
}
.registration-pass-require-modal__content.active {
     transform: scale(1);
 }
/* Текст*/
.registration-pass-require-modal__content-text {
    display: inline-flex;
    gap: 15px;
    align-items:flex-start;
    width: 579px;
    background: #FFFFFF;
    color: rgba(54, 59, 77, 0.9);
    padding: 16px 20px 16px 12px;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    position: absolute;
    filter: drop-shadow(0px 3px 16px rgba(54, 58, 72, 0.08));
    border-radius: 2px;
}
/*Стрелка */
.registration-pass-require-modal__content-text::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 10%;
    margin-left: -35px;
    margin-top: -105px;
    border-width: 14px;
    transform: rotate(180deg);
    border-style: solid;
    border-color: #FFFFFF transparent transparent transparent;
    filter: drop-shadow(0px 3px 16px rgba(54, 58, 72, 0.08));
}
.content-text__warning-icon {
    margin-top: 4px;
}
.content-text__close-icon {
    margin-top: 4px;
    cursor: pointer;
}
.content-text__warning-icon-mobile {
    display: none;
}
.content-text__close-icon-mobile {
    display: none;
}

/*---------Адаптивная версия-----------------------------------------------------------------------------------------*/
@media (max-width: 1280px) {
    .registration-pass-require-modal__content-text  {
        width: 450px;
        font-size: 14px;
        line-height: 16px;
    }
}
@media (max-width: 767px) {
    .registration-pass-require-modal__content-text  {
        width: 380px;
        font-size: 13px;
        line-height: 16px;
    }
}
@media (max-width: 767px) {
    .registration-pass-require-modal__wrapper {
        top: 290px;
    }
    .registration-pass-require-modal__content-text  {
        width: 343px;
        font-size: 12px;
        background-color: #373C4E;
        border-radius: 24px;
        color: #FFFFFF;
        line-height: 20px;
        letter-spacing: -0.01em;
    }
    .registration-pass-require-modal__content-text::after {
        display: none;
    }
    .content-text__warning-icon {
       display: none;
    }
    .content-text__warning-icon-mobile {
        display: initial;
    }
    .content-text__close-icon {
       display: none;
    }
    .content-text__close-icon-mobile {
        display: initial;
        cursor: pointer;
    }
}
