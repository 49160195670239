/*------Основной конейнер----------------------------------------------------------------------------------------------*/
.call-voting-page-question-card-check {
    display: flex;
}
.call-voting-page-question-card-check__main_voted {
    background: #4ED4A9;
    border-radius: 16px;
    padding: 6px 0 0;
}
.call-voting-page-question-card-check__wrapper {
    display: flex;
    flex-direction: column;
    background-color: #FFFFFF;
    border-radius: 16px;
    box-shadow: 0 3px 16px rgba(54, 58, 72, 0.08);
    padding: 32px 32px 40px 32px;
    gap: 24px;
    width: 100%;
}
/*------Стилизация для заголовка--------------------------------------------------------------------------------------*/
.call-voting-page-question-card-check__title {
    display: flex;
    flex-direction: column;
    gap: 16px;
    border-bottom: 1px solid rgba(54, 59, 77, 0.2);
    padding-bottom: 24px;
}
.call-voting-page-question-card-check__title h3 {
    font-size: 18px;
    font-weight: 600;
    line-height: 24px;
    color: rgba(54, 59, 77, 0.9);
    margin: auto auto auto 0;
}
.call-voting-page-question-card-check__select-answer {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    color: rgba(54, 59, 77, 0.4);
    margin: auto auto auto 0;
}
.call-voting-page-question-card-check__rule-text {
    padding-right: 16px;
}
.call-voting-page-question-card-check__rule-text_voted {
    padding-right: 16px;
    border-right: 1px solid rgba(54, 59, 77, 0.3)
}
.call-voting-page-question-card-check__voted-container {
    display: flex;
    background: rgba(78, 212, 169, 0.15);
    border-radius: 24px;
    margin: auto auto auto 0;
}
.call-voting-page-question-card-check__icon {
    width: 4px;
    height: 4px;
    margin: auto 8px auto 12px;
}
.call-voting-page-question-card-check__voted-text {
    font-style: normal;
    font-weight: 300;
    font-size: 12px;
    line-height: 20px;
    color: #4ED4A9;
    opacity: 0.9;
    margin: auto 12px auto 0;
}
/*------Стилизация для блока с чекбоксам------------------------------------------------------------------------------*/
.call-voting-page-question-card-check__select-checkboxes-block {
    /*display: flex;*/
    /*flex-direction: column;*/
    /*gap: 40px;*/
    color: rgba(54, 59, 77, 0.9);
    font-size: 18px;
}
.select-checkboxes-block__name-columns {
    display: flex;
    flex-direction: row;
    gap: 40px;
    padding-bottom: 40px;
}
.name-columns__width-column {
    width: 240px;
}

/*---------Адаптивная версия-----------------------------------------------------------------------------------------*/
@media (max-width: 992px) {
    .call-voting-page-question-card-check__select-checkboxes-block {
        font-size: 16px;
        overflow: scroll;
    }
    .name-columns__width-column {
        width: 142px;
    }
    .select-checkboxes-block__name-columns {
        gap: 32px;
        padding-bottom: 24px;
    }
}
@media (max-width: 767px) {
    .call-voting-page-question-card-check__wrapper {
        gap: 16px;
        padding: 16px 16px 24px 16px;
    }
    .call-voting-page-question-card-check__title {
        gap: 8px;
        padding-bottom: 16px;
    }
    .call-voting-page-question-card-check__title h3 {
        font-size: 16px;
        font-weight: 500;
    }
    .call-voting-page-question-card-check__select-answer {
        font-size: 12px;
        line-height: 20px;
    }

}
