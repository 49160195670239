/*------Контейнер для блока календарь---------------------------------------------------------------------------------*/
.calendar-container {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 24px;
    background-color: #FFFFFF;
    border-radius: 24px;
    padding: 32px 32px 39px 32px;
    width: 100%;
    box-shadow: 0 3px 16px 0 rgba(54, 58, 72, 0.08);
    height: 100%;
}
.calendar-container h1 {
    font-size: 24px;
    line-height: 32px;
    font-weight: 600;
    color: rgba(54, 59, 77, 0.9);
    padding-bottom: 32px;
}
.calendar-container-timetable__title-row {
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    gap: 27px;
}
.calendar-container-timetable__title-row span {
    font-size: 24px;
    font-weight: 600;
    color: rgba(54, 59, 77, 0.9);
    line-height: 32px;
}
.title-row__row {
    cursor: pointer;
}
/*------Стили для блока "Начало и конец регистрацич"-------------------------------------------------------------------*/
.calendar-container__run-end-vote {
    display: flex;
    flex-direction: row;
    gap: 16px;
    padding: 0 0 32px 0;
    font-size: 16px;
    line-height: 24px;
}
.run-end-vote {
    display: flex;
    flex-direction: column;
    gap: 16px;
}

/*------Кружки событий для календаря--------------------------------------------------------------------------------*/
.blue__circle {
    width: 8px;
    height: 8px;
    border-radius: 100%;
    /*position: absolute;*/
    /*right: 39px;*/
    /*top: 44px;*/
    background-color: #49B3FF;
}

.orange__circle {
    width: 8px;
    height: 8px;
    border-radius: 100%;
    /*position: absolute;*/
    /*right: 39px;*/
    /*top: 44px;*/
    background-color: #FF8A00;
}
.green__circle {
    width: 8px;
    height: 8px;
    border-radius: 100%;
    /*position: absolute;*/
    /*right: 39px;*/
    /*top: 44px;*/
    background-color: #4ED4A9;
}
.red__circle {
    width: 8px;
    height: 8px;
    border-radius: 100%;
    /*position: absolute;*/
    /*right: 39px;*/
    /*top: 44px;*/
    background-color: #FF4970;
}
.active_event_button {
    pointer-events: visible;
}
/*------Cтилизация календаря-----------------------------------------------------------------------------------------*/
.calendar-block {
    position: relative;
}
.react-calendar {
    border: white;
    width: 100%;
    pointer-events: none;
}
.react-calendar__tile--now {
    background: #CACCCF;
    color: rgba(54, 59, 77, 0.9);
}
.react-calendar__month-view__days button {
    border-right: 1px rgba(54, 59, 77, 0.4) solid;
    border-top: 1px rgba(54, 59, 77, 0.4) solid;
}
.react-calendar button:nth-child(7n) {
    border-right: none;
}

.react-calendar button:enabled:hover {
    cursor: pointer;
}

.react-calendar__navigation__label {
    font-size: 18px;
    pointer-events: none;
    max-width: 265px;
    color: rgba(54, 59, 77, 0.9);
}
.react-calendar__navigation button:hover {
    background: none;
}
.react-calendar__navigation button:enabled:hover {
    background: none;
}
.react-calendar__navigation button:enabled:focus {
    background: none;
}
.react-calendar__month-view__weekdays__weekday {
    padding: 0 24px 24px 24px;
    border-right: 1px solid rgba(54, 59, 77, 0.2);
}
.react-calendar__month-view__weekdays__weekday:last-child {
    border: none;
}
.react-calendar__navigation {
    justify-content: center;
    cursor: pointer;
    pointer-events: visible;
}
.react-calendar__month-view__days__day {
    /*padding: 24px 24px;*/
    font-size: 18px;
    opacity: 0.7;
    position: relative;
    /*z-index: 3;*/
    width: 75px;
    height: 75px;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: center;
    gap: 4px;
}
.react-calendar__month-view__days__day abbr {
    position: absolute;
    top: 30px;
    margin: 0 15px;
}
.react-calendar__month-view__weekdays__weekday abbr {
    text-decoration: none;
    font-size: 18px;
    color: rgba(54, 59, 77, 0.4);
    font-weight: 400;
    cursor: pointer;

}
.react-calendar__navigation__label::first-letter {
    text-transform: capitalize;
}
.react-calendar__navigation__prev2-button {
    display: none;
}
.react-calendar__navigation__prev-button {
    font-size: 24px;
}
.react-calendar__navigation__next-button {
    font-size: 24px;
}
.react-calendar__navigation__next2-button {
    display: none;
}
/*------Адаптивная версия-------------------------------------------------------------------------------------------*/
@media (max-width: 1280px) {
    .calendar-container-timetable__title-row span  {
        font-size: 21px;
        font-weight: 500;
        line-height: 138%;
    }
}
@media (max-width: 1280px) {
    .calendar-container {
        padding: 16px 16px 16px 16px;
        min-width: 370px;
        /*height: 380px;*/
        gap: 8px;
    }
    .calendar-container h1 {
        width: 370px;
        font-size: 21px;
        line-height: 29px;
        font-weight: 500;
        padding-bottom: 16px;
    }
    .calendar-container__run-end-vote span {
        font-size: 12px;
    }
    .react-calendar {
        min-width: 300px;
    }
    .react-calendar__viewContainer {
        width: 100%;
    }
    .react-calendar__month-view__weekdays__weekday {
        padding: 0 2px 2px 2px;
    }
    .react-calendar__month-view__days__day {
        font-size: 16px;
        width: 60px;
        height: 60px;
    }
    .react-calendar__month-view__days__day abbr{
        top: 25px;
    }
    .react-calendar__month-view__weekdays__weekday abbr {
        text-decoration: none;
        font-size: 16px;
    }
    .react-calendar__navigation {
        height: 30px;
    }
    .run-end-vote {
        gap: 8px;
    }
    .red__circle {
        width: 4px;
        height: 4px;
        top: 35px;
        left: 22px;
    }
    .green__circle {
        width: 4px;
        height: 4px;
        top: 35px;
        left: 22px;
    }
    .blue__circle {
        width: 4px;
        height: 4px;
        top: 35px;
        left: 22px;
    }
    .orange__circle {
        width: 4px;
        height: 4px;
        top: 35px;
        left: 22px;
    }
}

@media (max-width: 420px) {
    .calendar-container {
        min-width: 343px;
    }
    .calendar-container__run-end-vote {
        padding: 0 0 16px 0;
        gap: 0;
    }
    .red__circle {
        width: 4px;
        height: 4px;
        top: 35px;
        left: 22px;
    }
    .green__circle {
        width: 4px;
        height: 4px;
        top: 35px;
        left: 22px;
    }
    .blue__circle {
        width: 4px;
        height: 4px;
        top: 35px;
        left: 22px;
    }
    .orange__circle {
        width: 4px;
        height: 4px;
        top: 35px;
        left: 22px;
    }
    .calendar-container-timetable__title-row {
        gap: 16px;
    }
    .react-calendar__month-view__days__day {
        font-size: 16px;
        width: 50px;
        height: 55px;
    }
    .react-calendar__month-view__days__day abbr{
        top: 20px;
    }
    .react-calendar__navigation button {
        font-size: 16px;
    }
    .react-calendar .react-calendar__month-view__days button {
        border: none;
    }
    .react-calendar__month-view__weekdays__weekday {
        border: none;
    }
}