/*-----Стилизация кнопки зарегистрироваться---------------------------------------------------------------------------*/
.details-votes-page-general-info__reg-button {
    width: 213px;
    max-height: 48px;
    background-color: #0084FE;
    border-radius: 4px;
    padding: 12px 24px 12px 24px;
    color: #FFFFFF;
    font-size: 16px;
    line-height: 24px;
    cursor: pointer;
    font-weight: 500;
}
/*---------Адаптивная версия------------------------------------------------------------------------------------------*/
@media (max-width: 767px) {
    .details-votes-page-general-info__reg-button {
        max-width: 100%;
    }
}
@media (max-width: 420px) {
    .details-votes-page-general-info__reg-button {
        width: 100%;
        margin-top: 24px;
    }
}