.call-voting-page-vote-button-list__button {
    max-width: 169px;
    max-height: 48px;
    padding: 12px 24px 12px 24px;
    background-color: rgba(54, 59, 77, 0.08);
    border-radius: 4px;
    color: rgba(54, 59, 77, 0.35);
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    cursor: default;
}
.call-voting-page-vote-button-list__button-active {
    max-width: 169px;
    max-height: 48px;
    padding: 12px 24px 12px 24px;
    background: #0084FE;
    border-radius: 4px;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #FFFFFF;
    cursor: pointer;
}
.call-voting-page-vote-button-list__button.active {
    /*color: #FFFFFF;*/
    /*background-color: #0084FE;*/
    /*cursor: pointer;*/
    /*pointer-events: initial;*/
    display: none;
}
.call-voting-page-revote-button-list__button {
    max-width: 169px;
    max-height: 48px;
    padding: 12px 24px 12px 24px;
    background-color: #FFFFFF;
    border: 1px solid #0084FE;
    border-radius: 4px;
    color: rgba(54, 59, 77, 0.35);
    font-weight: 400;
    font-size: 16px;
    color: #0084FE;
    line-height: 24px;
    cursor: pointer;
    display: none;
}
.call-voting-page-revote-button-list__button.active {
    display: initial;
}
.call-voting-page-revote__container {
    display: flex;
    margin: 0 auto 0 0;
}
.call-voting-page-revote__button {
    width: 177px;
    height: 48px;
    margin: auto 24px auto 0;
    border: 1px solid #0084FE;
    border-radius: 4px;
    background: #FFFFFF;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #0084FE;
    cursor: pointer;
}
.call-voting-page-revote__success-text {
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    color: #4ED4A9;
    opacity: 0.9;
    margin: auto auto auto 0;
}
/*---------Адаптивная версия-----------------------------------------------------------------------------------------*/
@media (max-width: 767px) {
    .call-voting-page-vote-button-list__button, .call-voting-page-revote-button-list__button  {
        height: 40px;
        padding: 8px 24px 8px 24px;
        font-size: 14px;
        font-weight: 500;
    }
}
@media (max-width: 420px) {
    .call-voting-page-vote-button-list__button, .call-voting-page-revote-button-list__button  {
        max-width: 100%;
        height: 40px;
        padding: 8px 24px 8px 24px;
        font-size: 14px;
        font-weight: 500;
    }
}