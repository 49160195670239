/*------Основной контейнер--------------------------------------------------------------------------------------------*/
.call-voting-image-modal__container {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 56;
    width: 100%;
    height: 100vh;
    background: rgba(140, 140, 143, 0.2);
    display: flex;
}
/*------Контейнер для блока с контентом-------------------------------------------------------------------------------*/
.call-voting-image-modal {
    width: 70%;
    display: flex;
    flex-direction: column;
    padding: 0.88em 0.88em 0 0;
    box-shadow: 0 8px 22px rgba(188, 188, 188, 0.25);
    background-color: #FFFFFF;
    border-radius: 0.22em;
    position: fixed;
    max-height: 100%;
    overflow: auto;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
/*------Контейнер для блока с заголовком------------------------------------------------------------------------------*/
.call-voting-image-modal__title {
    display: flex;
    flex-direction: row;
    justify-content: right;
    align-items: center;
}
.call-voting-image-modal__title img {
    cursor: pointer;
    width: 1.33em;
    height: 1.33em;
}
/*---------Адаптивная версия-----------------------------------------------------------------------------------------*/
@media (max-width: 768px) {
    .call-voting-image-modal {
        width: 100%;
    }
    .call-voting-image-modal__title img {
        cursor: pointer;
        width: 16px;
        height: 16px;
    }
}