.materials-vote-question__wrapper {
    font-size: 14px;
    font-weight: 400;
    color: rgba(54, 59, 77, 0.9);
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
    white-space: nowrap;
}
.materials-vote-question-select-arrow {
    width: 10px;
    height: 10px;
    cursor: pointer;
    margin-left: 8px;
}
/*---------Адаптивная версия-----------------------------------------------------------------------------------------*/
@media (max-width: 992px) {
    .materials-vote-question__wrapper {
        font-size: 11px;
    }
}
@media (max-width: 767px) {
    .materials-vote-question__wrapper {
        font-size: 14px;
    }
}
