/*-----Основной контейнер---------------------------------------------------------------------------------------------*/
.my-profile-page-personal-data__wrapper {
    display: flex;
    flex-direction: column;
    padding: 32px 32px 40px 32px;
    box-shadow: 0 6px 16px 0 rgba(54, 58, 72, 0.07);
    border-radius: 24px;
    background: #FFFFFF;
    min-width: 300px;
}
/*-----Cтилизация заголовка-----------------------------------------------------------------------------------------------*/
.my-profile-page-personal-data__title {
    font-size: 24px;
    font-weight: 600;
    line-height: 110%;
    color: rgba(54, 59, 77, 0.9);
    padding-bottom: 32px;
}
.my-profile-page-personal-data__title-mobile {
    display: none;
    font-size: 21px;
    font-weight: 500;
    line-height: 138%;
    color: rgba(54, 59, 77, 0.9);
    padding-bottom: 16px;
}
/*-----Cтилизация формы-----------------------------------------------------------------------------------------------*/
.my-profile-page-personal-data__form {
    display: flex;
    gap: 24px;
    flex-direction: column;
    padding-bottom: 32px;
    position: relative;
}
.my-profile-page-personal-data__form-input {
    display: flex;
    gap: 16px;
    flex-direction: column;
    color: rgba(54, 59, 77, 0.9);

}
.my-profile-page-personal-data__form-input input {
    width: 100%;
    max-height: 48px;
    padding: 12px 0 12px 24px;
    outline: none;
    font-size: 18px;
    font-weight: 400;
    color: rgba(54, 59, 77, 0.9);
    border-radius: 8px;
    border: 1px rgba(54, 59, 77, 0.3) solid;
}
.my-profile-page-personal-data__form-input label {
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
    color: rgba(54, 59, 77, 0.9);
}
/*------Сообщение об ошибки данных полей------------------------------------------------------------------------------*/
.my-profile-page-personal-data__error-message {
    color: #FF4970;
    font-size: 12px;
    position: absolute;
    bottom: 8px;
}
.my-profile-page-personal-data__success-message {
    color: #4ED4A9;
    font-size: 12px;
    position: absolute;
    bottom: 8px;
}
/*------Адаптивная версия---------------------------------------------------------------------------------------------*/
@media (max-width: 1024px) {
    .my-profile-page-personal-data__wrapper {
        padding: 16px 16px 24px 16px;
    }
    .my-profile-page-personal-data__title {
        font-size: 21px;
        font-weight: 500;

    }
    .my-profile-page-personal-data__form {
        gap: 16px;
        padding-bottom: 24px;
    }
    .my-profile-page-personal-data__form-input {
        gap: 8px;

    }
    .my-profile-page-personal-data__form-input input {
        font-size: 16px;
        line-height: 22px;
        height: 40px;
        padding: 12px 0 12px 12px;
    }
    .my-profile-page-personal-data__form-input label {
        font-size: 16px;
        line-height: 22px;
    }
}
@media (max-width: 420px) {
    .my-profile-page-personal-data__form-input input {
        padding: 10px 0 10px 16px;
    }
    .my-profile-page-personal-data__title {
        display: none;
    }
    .my-profile-page-personal-data__title-mobile {
        display: block;
    }

    .__my-profile-page-personal-date__hidden-e-mail {
        display: none;
    }
}