.calendar-votes-timetable__wrapper {
    display: flex;
    flex-direction: column;
    gap: 24px;
    position: relative;
}
.calendar-votes-timetable__events-block {
    min-height: 100px;
    max-height: 240px;
    overflow: auto;
}
.calendar-votes-timetable__hidden-border {
    position: absolute;
    background: white;
    width: 100%;
    height: 4px;
    top: 85px;
    z-index: 5;
}
/*------Адаптивная версия---------------------------------------------------------------------------------------------*/
@media (max-width: 1024px) {
    .calendar-votes-timetable__hidden-border {
        top: 72px;
    }
}
@media (max-width: 992px) {
    .calendar-votes-timetable__hidden-border {
        top: 72px;
    }
}
@media (max-width: 420px) {
    .calendar-votes-timetable__hidden-border {
        top: 70px;
    }
    .calendar-votes-timetable__wrapper {
        max-height: 50vh;
        min-height: 20vh;
    }

}