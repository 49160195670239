/*------Основной контейнер--------------------------------------------------------------------------------------------*/
.call-voting-page__wrapper {
    display: flex;
    flex-direction: column;
    gap: 32px;
    padding-bottom: 112px;
}
/*------Стилизация заголовка------------------------------------------------------------------------------------------*/
.call-voting-page__title {
    display: flex;
    flex-direction: column;
    gap: 30px;
}
.call-voting-page-title__title {
    font-weight: 600;
    font-size: 24px;
    color: rgba(54, 59, 77, 0.9);
    line-height: 32px;
}
/*------Стилизация кнопки детали голосования---------------------------------------------------------------------------*/
.call-voting-page-details-vote-show-statistics-vote {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    max-width: 100%;
}
.call-voting-page-details-vote-show-statistics-vote__block {
    max-height: 96px;
    background-color: #FFFFFF;
    border-radius: 16px;
    box-shadow: 0 3px 16px rgba(54, 58, 72, 0.08);
    padding: 16px 16px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 12px;
}
.call-voting-page-details-vote-show-statistics-vote__block-statistics {
    display: flex;
    flex-direction: row;
    font-size: 16px;
    gap: 6px;
}
.call-voting-page-details-vote-show-statistics-vote__block-statistics-title {
    color: rgba(54, 59, 77, 0.4);
}
.call-voting-page-details-vote-show-statistics-vote__block-statistics-count {
    color: rgba(54, 59, 77, 0.9);
    font-weight: 600;
}
.call-voting-page-title__details-btn {
    width: 210px;
    max-height: 40px;
    padding: 8px 0 8px 0;
    border-radius: 4px;
    border: 1px solid #0084FE;
    background-color: #FBFBFC;
    color: #0084FE;
    font-size: 16px;
    line-height: 24px;
    cursor: pointer;
}
/*------Синиее сообщение ДЕТЕЛИ ГОЛОСОВАНИЯ для мобильной версии------------------------------------------------------*/
.call-voting-page-title__details-icon {
    display: none;
    color: #0075FF;
    font-weight: 300;
    font-size: 12px;
    cursor: pointer;
}
.call-voting-page-title__details-icon img {
    padding-right: 9px;
}
/*------Стилизация блока с кнопкой и ссылкой--------------------------------------------------------------------------*/
.call-voting-page__buttons {
   display: flex;
    flex-direction: row;
    align-items: center;
}
.call-voting-page__buttons-link {
    display: initial;
    outline: none;
    color: #0075FF;
    font-size: 16px;
    line-height: 133%;
}
.call-voting-page__buttons-link.hidden {
    display: none;
}
/*------Адаптивная версия---------------------------------------------------------------------------------------------*/
@media (max-width: 767px) {
    .call-voting-page__wrapper {
        padding-bottom: 2px;
        gap: 16px;
    }
    .call-voting-page-title__title {
        font-size: 21px;
        font-weight: 500;
        line-height: 138%;
    }
    .call-voting-page-details-vote-show-statistics-vote {
        flex-direction: column;
        align-items: flex-start;
        gap: 8px;
    }
    .call-voting-page-title__details-btn {
        font-size: 14px;
    }
    .call-voting-page-details-vote-show-statistics-vote__block {
        padding: 12px 12px;
    }
    .call-voting-page-details-vote-show-statistics-vote__block-statistics {
        font-size: 14px;
    }
}
@media (max-width: 420px) {
    .call-voting-page__title {
        gap: 6px;
    }
    .call-voting-page-title__details-btn  {
        display: none;
    }
    .call-voting-page-title__details-icon {
        display: block;
        color: #0075FF;
        font-weight: 300;
    }
    .call-voting-page__buttons {
        flex-direction: column;
        align-items: initial;
        gap: 12px;
    }
}