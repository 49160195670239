/*------Основной контейнер--------------------------------------------------------------------------------------------*/
.details-votes-page__wrapper {

    display: flex;
    flex-direction: column;
    gap: 32px;

    padding-bottom: 132px;
    position: relative;

    @media (max-width: 1280px) {
        padding-bottom: 70px;
    }

    @media (max-width: 767px) {
        padding-bottom: 30px;
    }
}
/*-----Основной контейнер для блоков общая инофрмация и ознакомиться с вопросами---------------------------------------*/
.details-votes-page__main-content {
    height: 100%;
    background-color: #FFFFFF;
    border-radius: 24px;
    box-shadow: 0 3px 16px rgba(54, 58, 72, 0.08);
    padding: 32px 32px 40px 32px;
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 24px;
}
/*-----Стилизация кнопок-переключателей--------------- ---------------------------------------------------------------*/
.details-votes-page-switch__buttons {
    display: flex;
    font-size: 20px;
    font-weight: 500;
    line-height: 32px;
    color: rgba(54, 59, 77, 0.9);
    border-bottom: 1px #EEEEEE solid;
}
.details-votes-page-switch-buttons__button {
    padding-bottom: 8px;
    border-bottom: 2px #FFFFFF solid;
    margin-right: 48px;
    cursor: pointer;
}
.details-votes-page-switch-buttons__button:hover {
    border-bottom: 2px #0084FE solid;
}
.active-details-votes-page-switch-buttons__button {
    padding-bottom: 8px;
    border-bottom: 2px #0084FE solid;
    margin-right: 48px;
    cursor: pointer;
}
._mobile-read-questions-bnt {
    display: none;
}
._read-questions-bnt {
    display: initial;
}
/*------Cтилизация блока основного контента---------------------------------------------------------------------------*/
.details-votes-page__main-content {
    display: flex;
    flex-direction: column;
    gap: 32px;
}
/*------Cтилизация блока для стрелок в мобильной версии---------------------------------------------------------------*/
.detail-votes-page__row-prev-next-btn {
    display: none;
}
.row-prev-next-btn__row-prev {
    position: absolute;
    top: -10px;
    left: 0;
    cursor: pointer;
    transform: rotate(180deg);
    display: none;
}
.row-prev-next-btn__row-prev.active {
    display: inherit;
}
.row-prev-next-btn__row-next {
    display: none;
}
.row-prev-next-btn__row-next.active {
    position: absolute;
    top: -10px;
    right: 0;
    cursor: pointer;
    display: inherit;
}
/*-----Адаптивная версия----------------------------------------------------------------------------------------------*/
@media (max-width: 1024px) {
    .details-votes-page-switch__buttons {
        font-size: 18px;
        font-weight: 400;
        line-height: 114%;
        white-space: nowrap;
    }
}
@media (max-width: 992px) {
    .details-votes-page-switch__buttons {
        display: flex;
        overflow-x: hidden;
        font-size: 16px;
    }
}
@media (max-width: 767px) {
    .detail-votes-page__row-prev-next-btn {
        display: flex;
        position: relative;
        justify-content: space-between;
    }
    .details-votes-page__main-content {
        gap: 16px;
    }
}
@media (max-width: 767px) {
    .details-votes-page__main-content {
        gap: 16px;
    }
    .details-votes-page__wrapper {
        gap: 16px;
    }
}
._mobile-read-questions-bnt {
    display: inherit;
}
._read-questions-bnt {
    display: none;
}

@media (max-width: 767px) {
    .details-votes-page__main-content {
        padding: 16px 16px 24px 16px;
        gap: 16px;
    }
}
@media (max-width: 420px) {
    .details-votes-page-switch__buttons {
        border-bottom: 2px #FFFFFF solid;
    }
    .details-votes-page-switch-buttons__button {
        margin-right: 24px;
    }
    .active-details-votes-page-switch-buttons__button{
        margin-right: 24px;
    }
    .details-votes-page-switch-buttons__button:hover {
        border-bottom: 2px #0084FE solid;
        cursor: pointer;
    }
}

