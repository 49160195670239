/*------Основной стиль для модального окна----------------------------------------------------------------------------*/
.header-my-profile-modal__wrapper {
    background-color: #4A4F60;
    min-width: 220px;
    height: 112px;
    position: absolute;
    right: 0;
    top: 56px;
    opacity: 0;
    z-index: 2;
    pointer-events: none;
}
.header-my-profile-modal__wrapper.active {
    opacity: 1;
    transform: scale(1);
    pointer-events: all;
}
/*------Стилизация для контента модального окна------------------------------------------------------------------------*/
.header-my-profile-modal__modal-content {
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
    background-color: #4A4F60;
    position: relative;
    transform: scale(0.5);
    justify-content: center;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
}
.header-my-profile-modal__modal-content.active {
    transform: scale(1);
}
.header-my-profile-modal__modal-content img {

}
.header-my-profile-modal__modal-content span {
    cursor: pointer;
    padding: 16px 16px;
    color: #FFFFFF;
    display: flex;
    align-items: center;
}
.header-my-profile-modal__modal-content a {
    text-decoration: none;
    color: #FFFFFF;
    border-bottom: 1px solid #61687E;
    padding: 16px 16px;
}
/*------Адаптивная версия(992px)--------------------------------------------------------------------------------------*/
@media (max-width: 992px) {
    .header-my-profile-modal__wrapper {
        top: 56px;
    }
}
/*------Адаптивная версия(660px)--------------------------------------------------------------------------------------*/
@media (max-width: 767px) {
    .header-my-profile-modal__wrapper {
        min-width: 180px;
        height: 90px;
    }
    .header-my-profile-modal__modal-content {
        font-size: 12px;
    }
    .header-my-profile-modal__modal-content a {
        padding: 8px 8px;
    }
    .header-my-profile-modal__modal-content span {
        padding: 8px 8px;
    }
}