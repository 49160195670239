/*---------Основной контейнер-----------------------------------------------------------------------------------------*/
.status-block__materials-vote {
    font-size: 12px;
    display: flex;
    flex-direction: column;
    padding: 0 32px 0 32px;
    gap: 16px;
    justify-content: center;
    height: 56px;
}
.materials-vote__status-icon {
    display: flex;
    gap: 5px;
    align-items: center;
    font-size: 14px;
    font-weight: 400;
    color: rgba(54, 59, 77, 0.9);
    max-width: 200px;
}
.status-icon__color-status_registered {
    color: #4ED4A9;
}
.status-icon__color-status_not-registered {
    color: #FF4970;
}
.status-icon__color-status_voted {
    color: #4569FF;
}
.status-icon__color-status_warning {
    color: #FF8A00;
}
.materials-vote__hidden-materials.active {
    display: none;
}
/*---------Адаптивная версия-----------------------------------------------------------------------------------------*/
@media (max-width: 1357px) {
    .status-block__materials-vote {
        /* white-space: nowrap; */
    }
}
@media (max-width: 1024px) {
    .status-block__materials-vote {
        /* white-space: nowrap; */
        padding: 0 6px 0 6px;
        gap: 12px;
    }
    .materials-vote__status-icon {
        font-size: 11px;
    }
    .status-block__materials-vote {
        height: 37px;
    }
}

@media (max-width: 1024px) {
    .status-block__materials-vote {
        /* white-space: nowrap; */
        padding: 0 6px 0 6px;
        gap: 12px;
    }
    .materials-vote__status-icon {
        font-size: 11px;
    }
    .status-block__materials-vote {
        height: 37px;
    }
}
@media (max-width: 767px) {
    .status-block__materials-vote {
        padding: 12px 0 0 0;
        font-size: 14px;
        grid-template-rows: 1fr;
    }
    .materials-vote__status-icon {
        font-size: 14px;
        padding-bottom: 8px;
    }
    .materials-vote__hidden-materials {
        display: none;
    }
}
@media (max-width: 420px) {
    .materials-vote__status-icon {
        max-width: 310px;
        margin: 15px auto 30px 0;
    }
}
@media (max-width: 420px) {
    .materials-vote__status-icon {
        max-width: 250px;
    }
}

