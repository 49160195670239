/*-----Основной контейнер---------------------------------------------------------------------------------------------*/
.details-votes-page-status-possible-revote-cancelreg__wrapper {
    display: flex;
    flex-direction: column;
    border-left: 1px solid rgba(54, 59, 77, 0.2);
    gap: 8px;
    padding: 0 32px 0 32px;
    justify-content: center;
    height: 56px;
}
/*-----Стилизация div  элементами-------------------------------------------------------------------------------------*/
.details-votes-page-status-possible-revote-cancelreg__possible-revote {
    display: inline-flex;
    flex-direction: row;
    gap: 8px;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    color: rgba(54, 59, 77, 0.6);
}
.details-votes-page-status-possible-revote-cancelreg__possible-revote span {
    color: black;
}
/*----Адаптивная версия----------------------------------------------------------------------------------------------*/
@media (max-width: 992px) {
    .details-votes-page-status-possible-revote-cancelreg__wrapper {
        justify-content: center;
        padding: 0 0 0 6px;
        gap: 4px;
        /*height: 37px;*/
    }
    .details-votes-page-status-possible-revote-cancelreg__possible-revote {
        font-size: 10px;
        white-space: nowrap;
    }
}
@media (max-width: 992px) {
    .details-votes-page-status-possible-revote-cancelreg__wrapper {
        height: 37px;
    }
}
@media (max-width: 767px) {
    .details-votes-page-status-possible-revote-cancelreg__wrapper {
        padding: 0;
        border: none;

    }
    .details-votes-page-status-possible-revote-cancelreg__possible-revote {
        font-size: 14px;

    }
}

