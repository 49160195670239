.no-match-routes__container {
    height: 100vh;
    position: fixed;
    z-index: 56;
    width: 100%;
    background: #FFFFFF;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
}
.no-match-routes {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.88em;
    flex-direction: column;
    border: 1px #0075FF solid;
    border-radius: 1.77em;
    padding: 1.33em 1.33em;
}
.no-match-routes__title {
    font-size: 1.77em;
    font-weight: 600;
    color: #0075FF;
}
.no-match-routes__information {
    line-height: 133%;
    font-size: 1em;
    color: #0075FF;
}
.no-match-routes__button {
    font-size: 1em;
    padding: 1em 1em;
    border-radius: 0.88em;
    cursor: pointer;
    background: #FFFFFF;
    border: 1px #0075FF solid;
    color: #0075FF;
}
/*-----Адаптивная версия(под разные устройства)-----------------------------------------------------------------------*/
@media (max-width: 1357px) {
    .no-match-routes__container {
        font-size: 16px;
    }
}
@media (max-width: 1280px) {
    .no-match-routes__container {
        font-size: 14px;
    }
}
