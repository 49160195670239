/*------Основной стиль-----------------------------------------------------------------------------------------------*/
.checkbox-status-vote {
    display: inline-flex;
    align-items: center;
    gap: 12px;
    cursor: pointer;
}
/*------Стилизация чебокса--------------------------------------------------------------------------------------------*/
.checkbox-status-vote_checkmark {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 20px;
    width: 20px;
    background-color: #FFFFFF;
    border-color: #0084FE;
    border-style: solid;
    border-width: 1px;
}
.checkbox-status-vote_container{
    display: flex;
    position: relative;

    cursor: pointer;
    font-size: 18px;
    user-select: none;
}
.checkbox-status-vote_container input {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    border: 0;
    padding: 0;
    white-space: nowrap;
    clip-path: inset(100%);
    clip: rect(0 0 0 0);
    overflow: hidden;
}

.checkbox-status-vote_container input:checked ~ .checkbox-status-vote_checkmark {
    background-color: #0084FE;
}
.checkbox-status-vote_checkmark:after {
    content: "";
    display: none;
}
.checkbox-status-vote_container input:checked ~ .checkbox-status-vote_checkmark:after {
    display: block;
}
.checkbox-status-vote_container .checkbox-status-vote_checkmark:after {
    width: 8px;
    height: 12px;
    border: solid white;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
    margin-bottom: 4px;
}
/*-----Адаптивная версия----------------------------------------------------------------------------------------------*/
@media (max-width: 767px) {
    .checkbox-status-vote span {
        background: #FFFFFF;
        font-size: 14px;
        line-height: 22px;
        color: #363B4D;
    }
}
