.details-votes-success-reg-modal__wrapper {
    display: flex;
    position: fixed;
    visibility: hidden;
    opacity: 0;
    transition: visibility .4s, opacity .4s linear;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
}
.details-votes-success-reg-modal__wrapper.active {
    visibility: visible;
    opacity: 1;
    transition: opacity .4s;
}
.details-votes-success-reg-modal__content {
    margin: auto auto 30px;
    display: flex;
    gap: 16px;
    align-items: center;
    background: #373C4E;
    padding: 26px 40px;
    border-radius: 16px;
    max-height: 88px;
    width: 900px;
}
.details-votes-success-reg-modal__content.active {
    transform: scale(1);
}
.details-votes-success-reg-modal__content span {
    font-size: 18px;
    color: #FFFFFF;
    line-height: 140%;
    font-weight: 400;
    /*margin: auto auto auto 0;*/
}
.details-votes-success-reg-modal__button {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    height: 48px;
    width: 119px;
    border-radius: 4px;
    border: 1px solid #FFFFFF;
    background: none;
    color: #FFFFFF;
    font-size: 16px;
    line-height: 24px;
    cursor: pointer;
    margin: auto 0 auto auto;
}
/*---------Адаптивная версия-----------------------------------------------------------------------------------------*/
@media (max-width: 930px) {
    .details-votes-success-reg-modal__content {
        padding: 20px 30px;
        width: 700px;
    }
    .details-votes-success-reg-modal__button {
        font-size: 14px;
    }
}
@media (max-width: 767px) {
    .details-votes-success-reg-modal__content {
        padding: 15px 20px;
        width: 500px;
    }
    .details-votes-success-reg-modal__content span {
        font-size: 16px;
    }
    .details-votes-success-reg-modal__button {
        font-size: 12px;
    }
}
@media (max-width: 767px) {
    .details-votes-success-reg-modal__content {
        padding: 10px 15px;
        width: 400px;
    }
    .details-votes-success-reg-modal__content span {
        font-size: 13px;
    }
    .details-votes-success-reg-modal__button {
        height: 40px;
        width: 110px;
        font-size: 12px;
        line-height: 22px;
    }
}
@media (max-width: 435px) {
    .details-votes-success-reg-modal__content {
        flex-direction: column;
        width: 350px;
        max-height: 150px;
    }
    .details-votes-success-reg-modal__content span {
        font-size: 14px;
        margin: 0 auto 0;
    }
    .details-votes-success-reg-modal__button {
        height: 45px;
        width: 140px;
        font-size: 15px;
        margin: 0 auto 0;
    }
}
@media (max-width: 435px) {
    .details-votes-success-reg-modal__content {
        width: 345px;
    }
}
