/*------Основной контейнер---------------------------------------------------------------------------------------------*/
.details-votes-page-datetime-end-reg-start-vote__wrapper {
    max-height: 96px;
    background-color: #FFFFFF;
    border-radius: 16px;
    box-shadow: 0 3px 16px rgba(54, 58, 72, 0.08);
    padding: 16px 24px 24px 24px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.datetime-info__two-numbers {
    width: 2ch;
}
.details-votes-page-datetime-end-reg-start-vote__wrapper.active {
    max-width: 664px;
}
/*------Cтилизация контейнера для div конец регистрации----------------------------------------------------------------*/
.details-votes-page-datetime-end-reg-start-vote__datetime-info-end-reg {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    gap: 8px;
    border-right: 1px solid rgba(54, 59, 77, 0.2);
    padding-right: 48px;
    margin-right: 48px;
}
.details-votes-page-datetime-end-reg-start-vote__datetime-info-end-reg.active {
    display: none;
}
/*------Cтилизация контейнера для div начало голосования--------------------------------------------------------------*/
.details-votes-page-datetime-end-reg-start-vote__datetime-info-start-vote {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    gap: 8px;
}
.details-votes-page-datetime-end-reg-start-vote__datetime-info-start-vote.active {
    display: none;
}
/*------Cтилизация контейнера для div конец голосования--------------------------------------------------------------*/
.details-votes-page-datetime-end-reg-start-vote__datetime-info-end-vote.active {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    gap: 8px;
}
.details-votes-page-datetime-end-reg-start-vote__datetime-info-end-vote {
  display: none;
}
/*------Cтилизация div c информацией конца регистрации----------------------------------------------------------------*/
.datetime-info__end-registration {
    display: flex;
    flex-direction: row;
    gap: 16px;
    color: rgba(54, 59, 77, 0.9);
}
.datetime-info__end-registration h2 {
    font-size: 32px;
    font-weight: 600;
    line-height: 40px;
}
.end-registration__time-info {
    opacity: 40%;
    font-size: 14px;
    font-weight: 400;
    line-height: 26px;
    padding-top: 14px;
    display: flex;
    flex-wrap: wrap;
    gap: 4px;
}
/*------Cтилизация div c информацией начала голосования---------------------------------------------------------------*/
.datetime-info__start-vote {
    display: flex;
    flex-direction: row;
    gap: 16px;
    color: rgba(54, 59, 77, 0.9);
}
.datetime-info__start-vote h2 {
    font-size: 32px;
    font-weight: 600;
    line-height: 40px;
}
.start-vote__time-info {
    opacity: 40%;
    font-size: 14px;
    font-weight: 400;
    line-height: 26px;
    padding-top: 14px;
    display: flex;
    flex-wrap: wrap;
    gap: 4px;
}
/*------Cтилизация div с цветным ползунком отсчета дней---------------------------------------------------------------*/
.datetime-info__range {
    -webkit-appearance: none;
    -moz-appearance: none;
    width: 100%;
}
.datetime-info__range-orange::-moz-range-progress {
    background: #FF8A00;
    width: 100%;
    height: 6px;
}
.datetime-info__range-green::-moz-range-progress {
    background: #4ED4A9;
    width: 100%;
    height: 6px;
}
.datetime-info__range-orange::-moz-range-track {
    width: 100%;
    height: 6px;
    background: #FF8A00;
    opacity: 40%;
}
.datetime-info__range-orange::-webkit-slider-runnable-track {
    width: 100%;
    position: absolute;
    top: 6px;
    height: 6px;
    -webkit-appearance: none;
    background: #FFE4C4;
}
.datetime-info__range-green::-moz-range-track {
    width: 100%;
    height: 6px;
    background: #4ED4A9;
    opacity: 40%;
}
.datetime-info__range-green::-webkit-slider-runnable-track {
    width: 100%;
    position: absolute;
    top: 6px;
    height: 6px;
    -webkit-appearance: none;
    background: #DCF9F0;
}
/*------Cтилизация ползунка-------------------------------------------------------------------------------------------*/
.datetime-info__range-orange::-moz-range-thumb {
    height: 20px;
    width: 3px;
    border-radius: 4px;
    background: #FF8A00;
    border: none;
}
.datetime-info__range-orange::-webkit-slider-thumb {
    -webkit-appearance: none;
    height: 20px;
    width: 5px;
    background: #FF8A00;
    border-radius: 4px;
    margin-top: -7px;
    pointer-events: auto;
    border: none;
}
.datetime-info__range-green::-moz-range-thumb {
    height: 20px;
    width: 3px;
    border-radius: 4px;
    background: #4ED4A9;
    border: none;
}
.datetime-info__range-green::-webkit-slider-thumb {
    -webkit-appearance: none;
    height: 20px;
    width: 5px;
    background: #4ED4A9;
    border-radius: 4px;
    margin-top: -7px;
    pointer-events: auto;
    border: none;
}
/*------Стилизация div для таймера Конец Голосования------------------------------------------------------------------*/
.end-registration__time-info-input-reg {
    position: relative;
}
.time-info-input-reg__timer-strip {
    position: absolute;
    background-color: #FF8A00;
    width: 100%;
    height: 6px;
    top: 6px;
}
/*------Стилизация div для таймера Начало Голосования------------------------------------------------------------------*/
.start-vote__time-info-input-vote {
    position: relative;
}
.time-info-input-vote__timer-strip {
    position: absolute;
    background: #4ED4A9;
    width: 100%;
    height: 6px;
    top: 6px;
}
/*-----Адаптивная версия----------------------------------------------------------------------------------------------*/
@media (max-width: 1280px) {
    .details-votes-page-datetime-end-reg-start-vote__wrapper {
        padding: 8px 8px 8px 8px;
    }
    .details-votes-page-datetime-end-reg-start-vote__datetime-info-end-reg,
    .details-votes-page-datetime-end-reg-start-vote__datetime-info-start-vote,
    .details-votes-page-datetime-end-reg-start-vote__datetime-info-end-vote.active {
        gap: 0;
    }
    .datetime-info__end-registration h2, .datetime-info__start-vote h2 {
        font-size: 24px;
    }
    .end-registration__time-info, .start-vote__time-info {
        font-size: 12px;
        padding-top: 0;
        padding-right: 15px;
    }
}
@media (max-width: 1024px) {
    .details-votes-page-datetime-end-reg-start-vote__datetime-info-end-reg {
        padding-right: 16px;
        margin-right: 16px;
    }
    .datetime-info__end-registration span, .datetime-info__start-vote span {
        padding-right: 6px;
    }
    .details-votes-page-datetime-end-reg-start-vote__wrapper {
        max-height: 150px;
    }
}
@media (max-width: 992px) {
    .details-votes-page-datetime-end-reg-start-vote__datetime-info-end-reg {
        padding-right: 16px;
        margin-right: 16px;
    }
    .datetime-info__end-registration, .datetime-info__start-vote {
        gap: 8px;
    }
}
@media (max-width: 992px) {
    .datetime-info__end-registration, .datetime-info__start-vote {
        display: block;

    }
    .datetime-info__end-registration h2, .datetime-info__start-vote h2 {

    }
}
@media (max-width: 992px) {
    .details-votes-page-datetime-end-reg-start-vote__wrapper {
        max-height: 150px;
    }
    .datetime-info__end-registration, .datetime-info__start-vote {
        display: block;
    }
}
@media (max-width: 767px) {
    .details-votes-page-datetime-end-reg-start-vote__wrapper {
        max-height: 79px;
        padding: 8px 7px 10px 0;
    }
    .details-votes-page-datetime-end-reg-start-vote__datetime-info-end-reg,
    .details-votes-page-datetime-end-reg-start-vote__datetime-info-start-vote,
    .details-votes-page-datetime-end-reg-start-vote__datetime-info-end-vote.active {
        width: 100%;
        padding: 0 13px 5px 10px;
    }
    .datetime-info__end-registration, .datetime-info__start-vote {
        display: inline-flex;
        gap: 6px;
    }
    .datetime-info__end-registration h2, .datetime-info__start-vote h2 {
        white-space: nowrap;
        font-size: 18px;
    }
    .end-registration__time-info, .start-vote__time-info {
        white-space: nowrap;
        padding-top: 7px;
    }
    .datetime-info__end-registration input, .datetime-info__start-vote input {
        margin-bottom: 16px;
    }
    ._hidden-block__end-registration-start-vote {
        display: none;
    }
}
@media (max-width: 420px) {
    .end-registration__time-info, .start-vote__time-info {
        font-size: 11px;
        padding-top: 10px;
        display: block;
    }
}




