/*------Основной стиль для бургер-меню--------------------------------------------------------------------------------*/
.burger-menu {
    position: fixed;
    width: 272px;
    height: 100vh;
    top: 0;
    left: 0;
    transform: translate(-100%);
}
.burger-menu.active {
    transform: scale(1);
    z-index: 4;
    opacity: 1;
    pointer-events: all;
    transition: 0.5s;
}
.blur {
    left: 30%;
    backdrop-filter: blur(0);
    position: absolute;
}
/*------Стилизация для контента бургер-меню-----------------------------------------------------------------------------*/
.burger-menu__content {
    width: 100%;
    height: 100%;
    background-color: #373C4E;
    display: flex;
    flex-direction: column;
    padding: 26px 16px 64px 23px;
    transform: scale(1);
    color: #FFFFFF;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
}
.burger-menu__logotype-block {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    gap: 133px;
    padding-bottom: 45px;
}
.burger-menu__logotype-block span:first-child {
    font-size: 12px;
    color: #FFFFFF;
    margin-right: 12px;
    text-decoration: underline;
}
.burger-menu__logotype-block span:last-child {
    font-size: 12px;
    color: #FFFFFF;
    margin-right: 12px;
    opacity: 0.4;
}

/*------Стилизация для блока с навигацией бургер-меню-----------------------------------------------------------------*/
.burger-menu__link-page {
    display: flex;
    flex-direction: column;
    gap: 24px;
    color: #FFFFFF;
    padding-bottom: 30px;
}
.burger-menu__link-page a {
    color: #FFFFFF;
    text-decoration: none;
}
/*------Стилизация для блока с кнопкой увеличить шрифт-----------------------------------------------------------------*/
.burger-menu__toggle-font {
    display: flex;
    flex-direction: row;
    padding-bottom: 50px;
    gap: 16px;
    color: #FFFFFF;
    position: relative;
    align-items: center;
}
.toggle-font__button {
    position: relative;
    display: inline-block;
    width: 42px;
    height: 24px;
}
.toggle-font__button input {
    opacity: 0;
    width: 0;
    height: 0;
}
.toggle-font__slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #FFFFFF;
    border-radius: 16px;
}
.toggle-font__slider:before {
    position: absolute;
    content: "";
    height: 20px;
    width: 20px;
    right: 22px;
    bottom: 2px;
    background-color: #373C4E;
    border-radius: 100%;
    transition: 0.4s;
}
input:checked + .toggle-font__slider {
    background-color: #FFFFFF;
    opacity: 60%;
}
input:checked + .toggle-font__slider:before {
    transform: translateX(21px);
}

/*------Стилизация для блока с политикой конф-ти и support--------------------------------------------------------------*/
.burger-menu__politic-support {
    display: flex;
    flex-direction: column;
    gap: 24px;
    padding-bottom: 20px;
    margin: 45px auto 0 0;
}
.burger-menu__politic-support a {
    color: #FFFFFF;
}
/*------Стилизация для блока с формой поиска--------------------------------------------------------------------------*/
.burger-menu__search-form-logo {
    display: flex;
    flex-direction: column;
    position: relative;
    gap: 41px;
    margin: 20px auto 0 0;
}
.burger-menu__search-form-logo input {
    width: 100%;
    height: 34px;
    border-radius: 4px;
    background-color: rgba(101, 105, 117, 0.5);
    outline: none;
    padding: 8px 0 8px 40px;
    color: #FFFFFF;

}
.burger-menu__search-form-logo img {
    position: absolute;
    top:  82px;
    left: 20px;
    opacity: 40%;
}
.burger-menu__logout-button {
    margin: 10px auto 0 0;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
}
/*------Адаптивная версия(420px)---------------------------------------------------------------------------------------*/
@media (max-width: 570px) {
    .blur {
        width: 100vw;
        height: 100vh;
    }
}