/*------Cтилизация блока c кнопками фильтры и сортировка--------------------------------------------------------------*/
.navigation-menu__select-buttons {
    display: flex;
    position: relative;
    flex-direction: row;
    align-content: center;
    gap: 14px;
    padding-bottom: 24px;
}
.select-buttons__filters-button {
    width: 154px;
    height: 40px;
    background-color: #FBFBFC;
    color: #0084FE;
    padding: 8px 24px 8px;
    font-size: 16px;
    border: 1px #0084FE solid;
    border-radius: 4px;
    cursor: pointer;
    display: flex;
    align-items: center;
}
.select-buttons__filters-button img {
    padding-right: 14px;
}
.select-buttons__sort-button {
    width: 177px;
    height: 40px;
    background-color: #FBFBFC;
    color: #0084FE;
    font-size: 16px;
    padding: 8px;
    border: 1px #0084FE solid;
    border-radius: 4px;
    cursor: pointer;
}
.select-buttons__sort-button img {
    padding-right: 13px;
    vertical-align: middle;
}
/*------Cтилизация блока c кнопками фильтры и сортировка(для мобильной версии)----------------------------------------*/
.select-buttons__mobile-filters-sort-button  {
    display: none;
}
.select-buttons__filters-red-circle, .select-buttons__sort-red-circle {
    display: none;
}
/*------Адаптивная версия--------------------------------------------------------------------------------------------*/
@media (max-width: 992px) {
    .navigation-menu__select-buttons {

    }
    .select-buttons__filters-button, .select-buttons__sort-button {
        display: none;
    }
    .select-buttons__mobile-filters-sort-button {
        display: flex;
        background: none;
        font-size: 15px;
        line-height: 121%;
        font-weight: 400;
        color: rgba(54, 59, 77, 0.4);
        cursor: pointer;
    }
    .select-buttons__mobile-filters-sort-button img {
        padding-right: 5px;
        cursor: pointer;
    }
    .select-buttons__filters-red-circle {
        position: absolute;
        display: inherit;
        top: 2px;
        left: 12px;
    }
    .select-buttons__sort-red-circle {
        position: absolute;
        display: inherit;
        top: 2px;
        left: 115px;
    }
}
@media (max-width: 767px) {
    .navigation-menu__select-buttons {
        padding-bottom: 0;
    }
}