/*------Основной стиль модального окна--------------------------------------------------------------------------------*/
.auth-forget-pass-modal {
    height: 100vh;
    width: 100vw;
    background-color: rgba(0,0,0,0.4);
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    pointer-events: none;
    transition: 0.5s;
}
/*-----Активация модального окна при клике------------------------------------------------------------------------------*/
.auth-forget-pass-modal.active {
    opacity: 1;
    transform: scale(1);
    pointer-events: all;
}
/*------Стилизация самого модального окна-----------------------------------------------------------------------------*/
.auth-forget-pass-modal__content {
    display: flex;
    flex-direction: column;
    gap: 40px;
    padding: 48px 40px 48px 40px;
    width: 548px;
    height: 328px;
    border-radius: 8px;
    background-color: #FFFFFF;
}
/*------Стилизация заголовка модального окна--------------------------------------------------------------------------*/
.auth-forget-pass-modal__content h1 {
    font-style: normal;
    font-weight: 600;
    font-size: 32px;
    line-height: 40px;
    color: rgba(54, 59, 77, 0.9);
}

/*-------Стилизация информации модального окна------------------------------------------------------------------------*/
.auth-forget-pass-modal__content span {
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 28px;
    color: #363B4D;
    opacity: 0.9;
}
/*------Стилизация кнопки модального окна-----------------------------------------------------------------------------*/
.auth-forget-pass-modal__content button {
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    width: 217px;
    height: 56px;
    border-radius: 4px;
    color: #FFFFFF;
    background-color: #0084FE;
    cursor: pointer;
}
/*------ Адаптивная версия--------------------------------------------------------------------------------------------*/
@media (max-width: 992px) {
    .auth-forget-pass-modal {
        display: flex;
        top: 50px;
        align-items: center;
        justify-content: center;
        pointer-events: none;
        background-color: inherit;
    }

    .auth-forget-pass-modal__content {
        gap: 24px;
        height: 230px;
        width: 380px;
        padding: 24px 16px 24px 16px;
    }
    .auth-forget-pass-modal__content h1 {
        font-size: 26px;
        line-height: 34px;
        font-weight: 600;
    }
    .auth-forget-pass-modal__content span {
        font-size: 16px;
        line-height: 26px;
        font-weight: 400;
    }
    .auth-forget-pass-modal__content button {
        width: 343px;
        height: 48px;
    }

}