/*------Основной контейнер--------------------------------------------------------------------------------------------*/
.read-questions-card-checkbox__checkbox-question-block {
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid rgba(54, 59, 77, 0.2);
    padding-bottom: 16px;
    width: 100%;
}
.checkbox-question-block__title {
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding-bottom: 16px;
}
.checkbox-question-block__title h3 {
    font-size: 18px;
    font-weight: 600;
    line-height: 24px;
    color: rgba(54, 59, 77, 0.9);
}
.checkbox-question-block__title h5 {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    color: rgba(54, 59, 77, 0.4);
}
.checkbox-question-block__title span {
    color: rgba(54, 59, 77, 0.9);
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
}
.checkbox-question-block__title select {
    background-color: #FFFFFF;
    width: 20px;
    cursor: pointer;
}

/*------Стилизация для блока с чекбоксам------------------------------------------------------------------------------*/
.checkbox-question-block__select-checkboxes-block {
    display: flex;
    flex-direction: column;
    padding-bottom: 16px;
    gap: 40px;
    color: rgba(54, 59, 77, 0.9);
    font-size: 18px;
}

/*------Адаптивная версия(800px)---------------------------------------------------------------------------------------*/
@media (max-width: 992px) {
    .checkbox-question-block__select-checkboxes-block {
        font-size: 16px;
        gap: 24px;
        overflow: scroll;
    }
    .checkbox-question-block__header-columns {
        font-size: 16px;
    }
    .checkbox-question-block__title {
        gap: 8px;
        padding-bottom: 16px;
    }
    .checkbox-question-block__title h3 {
        font-size: 16px;
        font-weight: 500;
    }
    .checkbox-question-block__title h5 {
        font-size: 12px;
        font-weight: 500;
    }
    .checkbox-question-block__title span {
        font-size: 12px;
    }
    .checkbox-question-block__title select {
        display: none;
    }

}
@media (max-width: 767px) {
    .checkbox-question-block__header-columns {
        font-size: 16px;
        grid-template-columns: 120px 12% 23% 16%;
    }
}

