/*-----Основной контейнер---------------------------------------------------------------------------------------------*/
.my-profile-page-set-pass__wrapper {
    display: flex;
    flex-direction: column;
    padding: 32px 32px 40px 32px;
    box-shadow: 0 6px 16px 0 rgba(54, 58, 72, 0.07);
    border-radius: 24px;
    background: #FFFFFF;
}
.my-profile-page-set-pass__wrapper h3 {
    font-size: 24px;
    font-weight: 600;
    line-height: 110%;
    color: rgba(54, 59, 77, 0.9);
    padding-bottom: 32px;
}
/*-----Cтилизация формы-----------------------------------------------------------------------------------------------*/
.my-profile-page-set-pass__form {
    display: flex;
    gap: 24px;
    flex-direction: column;
    padding-bottom: 32px;
}
.my-profile-page-set-pass__form-input {
    display: flex;
    gap: 16px;
    flex-direction: column;
    color: rgba(54, 59, 77, 0.9);
    position: relative;
}
.form-input__pass-fields {
    width: 100%;
    max-height: 48px;
    padding: 12px 0 12px 24px;
    outline: none;
    border-radius: 8px;
    border: 1px rgba(54, 59, 77, 0.3) solid;
    font-size: 18px;
    color: rgba(54, 59, 77, 0.9);
}
.form-input__pass-fields.active {
    border: 1px green solid;
}
.my-profile-page-set-pass__form-input label {
    font-size: 18px;
    color: rgba(54, 59, 77, 0.9);
    line-height: 24px;
    font-weight: 400;
}
/*-----Cтилизация иконки пароля---------------------------------------------------------------------------------------*/
.my-profile-page-set-pass__icon-pass {
    position: absolute;
    width: 20px;
    height: 22px;
    cursor: pointer;
    top: 53px;
    right: 17px;
}
.__my-profile-show-icon-pass {
    display: none;
}
.my-profile-page-set-pass__icon-pass.active {
    display: inherit;
}
/*------Сообщение о пароле ---------------------------------------------------------------------------------------------*/
.my-profile-page-set-pass__error-message {
    color: #FF4970;
    font-size: 12px;
    position: absolute;
    top: 90px;
}
.my-profile-page-set-pass__success-message {
    color: #4ED4A9;
    font-size: 12px;
    position: absolute;
    top: 70px;
}
/*------Адаптивная версия---------------------------------------------------------------------------------------------*/
@media (max-width: 1024px) {
    .my-profile-page-set-pass__wrapper {
        padding: 16px 16px 24px 16px;
    }
    .my-profile-page-set-pass__wrapper h3 {
        font-size: 21px;
        font-weight: 500;
        line-height: 138%;
        color: rgba(54, 59, 77, 0.9);
        padding-bottom: 16px;
    }
    .my-profile-page-set-pass__form {
        gap: 16px;
        padding-bottom: 24px;
    }
    .my-profile-page-set-pass__form-input  {
        gap: 8px;
    }
    .my-profile-page-set-pass__form-input input {
        width: 100%;
        max-height: 40px;
        padding: 12px 0 12px 12px;
        font-size: 18px;
    }
    .my-profile-page-set-pass__form-input label {
        font-size: 16px;
        line-height: 22px;
    }
    .my-profile-page-set-pass__icon-pass {
        top: 40px;
    }
    .my-profile-page-set-pass__error-message {
        top: 72px;
        font-size: 10px;
    }
}
@media (max-width: 420px) {
    .__my-profile-page-set-pass-hidden {
        display: none;
    }
    .__my-profile-show-icon-pass {
        display: inherit;
    }
    .my-profile-page-set-pass__form-input input {
        padding: 10px 0 10px 16px;
    }
}

