/*------Основной контейнер--------------------------------------------------------------------------------------------*/
.call-voting-name-rows__wrapper {
    display: flex;
    flex-direction: row;
    gap: 40px;
    padding-bottom: 40px;
}
.call-voting-name-rows__name-row {
    width: 240px;
    word-break: break-word;
    font-size: 18px;
}
/*------Стилизация квадратного чекбокса--------------------------------------------------------------------------------*/
.call-voting-checkbox__container {
    display: block;
    position: relative;
    padding-left: 34px;
    cursor: pointer;
    font-size: 18px;
    user-select: none;
}
.call-voting-checkbox__container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}
.call-voting-checkbox__checkmark {
    position: absolute;
    top: -2px;
    left: 58px;
    height: 24px;
    width: 24px;
    background-color: #FFFFFF;
    border-color: #0084FE;
    border-style: solid;
    border-width: 1px;
}
.call-voting-checkbox__container:hover input ~ .call-voting-checkbox__checkmark {
    background-color: #ccc;
}
.call-voting-checkbox__container input:checked ~ .call-voting-checkbox__checkmark {
    background-color: #0084FE;
}

.call-voting-checkbox__checkmark:after {
    content: "";
    position: absolute;
    display: none;
}
.call-voting-checkbox__container input:checked ~ .call-voting-checkbox__checkmark:after {
    display: block;
}
.call-voting-checkbox__container .call-voting-checkbox__checkmark:after {
    left: 6px;
    top: 5px;
    width: 8px;
    height: 10px;
    border: solid white;
    border-width: 0 1px 1px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}
/*------Стилизация круглого чекбокса--------------------------------------------------------------------------------*/
.call-voting-checkbox-radio__container {
    display: block;
    position: relative;
    padding-left: 34px;
    cursor: pointer;
    font-size: 18px;
    user-select: none;
}
.call-voting-checkbox-radio__container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}
.call-voting-checkbox-radio__checkmark {
    position: absolute;
    top: -2px;
    left: 58px;
    height: 24px;
    width: 24px;
    background-color: #FFFFFF;
    border-color: #0084FE;
    border-style: solid;
    border-width: 1px;
    border-radius: 100%;
}
.call-voting-checkbox-radio__container:hover input ~ .call-voting-checkbox-radio__checkmark {
    background-color: #ccc;
}
.call-voting-checkbox-radio__container input:checked ~ .call-voting-checkbox-radio__checkmark {
    background-color: #FFFFFF;
}

.call-voting-checkbox-radio__checkmark:after {
    content: "";
    position: absolute;
    display: none;
}
.call-voting-checkbox-radio__container input:checked ~ .call-voting-checkbox-radio__checkmark:after {
    display: block;
}
.call-voting-checkbox-radio__container .call-voting-checkbox-radio__checkmark:after {
    left: 4px;
    top: 4px;
    width: 14px;
    height: 14px;
    background: #0075FF;
    border-radius: 100%;
}

/*------Адаптивная версия(830px)---------------------------------------------------------------------------------------*/
@media (max-width: 767px) {
    .call-voting-name-rows__name-row {
        font-size: 16px;
    }
}
@media (max-width: 992px) {
    .call-voting-card-checkbox__name-question {
        word-wrap: break-word;
        line-height: 22px;
    }
    .call-voting-checkbox__checkmark {
        width: 20px;
        height: 20px;
        left:  50px;
    }
    .call-voting-checkbox-radio__checkmark {
        width: 20px;
        height: 20px;
        left:  50px;
    }
    .call-voting-checkbox__container .call-voting-checkbox__checkmark:after {
        left: 4px;
        top: 3px;
    }
    .call-voting-checkbox-radio__container .call-voting-checkbox-radio__checkmark:after {
        left: 2px;
        top: 2px;
    }
    .call-voting-name-rows__name-row {
        width: 140px;
    }
    .call-voting-name-rows__wrapper {
        display: flex;
        flex-direction: row;
        gap: 32px;
        padding-bottom: 24px;
    }
}
@media (max-width: 767px) {
    .call-voting-card-checkbox__list-answer {
        grid-template-columns: 136px 18% 18% 20%;
    }
    .list-answer-checkbox {
        width: 20px;
        height: 20px;
    }
}
