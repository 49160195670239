/*------Стилизация горизонтальной сетки для графика------------------------------------------------------------------*/
.card-question-horizontal-graph__horizontal-grid {
    width: 600px;
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 12px;
    margin-right: 95px;
    overflow: auto;
    height: 350px;
}
.horizontal-grid__position-table {
    position: absolute;
    border-bottom: 1px solid rgba(54, 59, 77, 0.05);
    max-height: 513px;
    width: 376px;
    rotate: -90deg;
    top: -75px;
    left: 72px;
    /*width: 568px;*/
}
.horizontal-grid__position-table td {
    border-top: 1px solid rgba(54, 59, 77, 0.05);
    border-left: 1px solid rgba(54, 59, 77, 0.05);
    height: 50px;
}
.horizontal-grid__position-table td:nth-child(10) {
    border: none;
}
.card-question-horizontal-graph__horizontal-grid::-webkit-scrollbar {
    width: 8px;
    height: 8px;/* ширина всей полосы прокрутки */
}

.card-question-horizontal-graph__horizontal-grid::-webkit-scrollbar-track {
    background: rgba(54, 59, 77, 0.1);        /* цвет зоны отслеживания */
}

.card-question-horizontal-graph__horizontal-grid::-webkit-scrollbar-thumb {
    background-color: darkgray;    /* цвет бегунка */
    border-radius: 20px;       /* округлось бегунка */
    /* отступ вокруг бегунка */
}


/*-----Адаптивная версия---------------------------------------------------------------------------------------------*/
@media (max-width: 992px) {
    .card-question-horizontal-graph__horizontal-grid {
        margin-right: 90px;
    }
    .horizontal-grid__position-table td {
        height: 40px;
    }
    .horizontal-grid__position-table {
        max-height: 513px;
        width: 376px;
        rotate: -90deg;
        top: -22px;
        left: 23px;
        /*width: 568px;*/
    }
}
@media (max-width: 767px) {
    .card-question-horizontal-graph__horizontal-grid {
        height: 350px;
        width: 100%;
    }
    .horizontal-grid__position-table td {
        height: 48px;
    }
    .horizontal-grid__position-table {
       width: 400px;
    }
    .horizontal-grid__position-table {
        top: -122px;
        left: 0;
    }
}
@media (max-width: 767px) {
    .card-question-horizontal-graph__horizontal-grid {
        overflow: auto;
        max-height: 300px;
    }
}