.search-modal {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 60px;
  right: -100px;
  width: 432px;
  max-width: 100%;
  max-height: 300px;
  overflow-y: scroll;
  z-index: 1;
  background: #4a4f60;
  border-radius: 2px;

  @media (max-width: 767px) {
    .search-modal {
      right: -80px;
      width: 300px;
      max-height: 250px;
    }
  }

  @media (max-width: 420px) {
    right: 16px;
    left: 16px;
    width: auto;
  }
}
.search-modal::-webkit-scrollbar {
  width: 10px;
  background: #4a4f60;
  border-radius: 4px;
}
.search-modal::-webkit-scrollbar-thumb {
  background: rgba(101, 105, 117, 0.7);
  border-radius: 4px;
}
.search-modal__result-container {
  display: flex;
  min-height: 56px;
  border-bottom: 1px solid #828385;
  cursor: pointer;
  box-sizing: border-box;
}
.search-modal__result-container:last-of-type {
  border-bottom: none;
}
.search-modal__result {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
  margin: auto auto auto 16px;
  width: 432px;
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
@media (max-width: 767px) {
  .search-modal__result-container {
    min-height: 48px;
  }
  .search-modal__result {
    font-size: 16px;
    line-height: 24px;
    max-width: 250px;
  }
}
