/*------Заголовок для главной страницы--------------------------------------------------------------------------------*/
.main-content__title {
    font-size: 32px;
    line-height: 125%;
    padding-bottom: 40px;
    color: rgba(54, 59, 77, 0.9);
    font-weight: 600;

    @media (max-width: 420px) {
        padding-top: 8px;
    }
}
/*------Стилизация для компонентов Мои голосования и Актуальное-------------------------------------------------------*/
.main-content__my-votes-actual {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-bottom: 16px;
    gap: 16px;
}
/*------Стилизация для компонентов Количество голосующих и Календарь голосования-------------------------------------*/
.main-content__amount-votes-and-calendar-votes {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 16px;
    padding-bottom: 42px;
}
/*------Стилизация для компонентов Гистограмма и Обозреватель криптовече---------------------------------------------*/
.gistogramma-and-observer-cryptoveche {
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 100%;
}
/*------Стилизация для компонентов пустые состояния-------------------------------------------------------------------*/
.main-content__empty-states-wrapper {
    display: flex;
    flex-direction: row;
    gap: 16px;
    padding-bottom: 125px;
}

.empty-states-component_img {
    @media (max-width: 1280px) {
        height: auto;
        max-height: 300px;
        object-fit: contain;
        object-position: left;
    }
    @media (max-width: 992px) {
        max-height: 300px;
        object-fit: contain;
        object-position: left;
    }
}

.main-content__empty-states-component {
    flex-grow: 1;
}

.main-content__empty-states-observer-amount {
    display: flex;
    flex-direction: column;
    gap: 16px;
    min-width: 50%;
}
.main-content__empty-states-myvotes-block {
    display: none;
}
.main-content__empty-states-qr-code {
    display: none;
}
/*------Адаптивная версия----------------------------------------------------------------------------------------------*/
@media (max-width: 992px) {
    .main-content__my-votes-actual {
        gap: 6px;
    }
    .main-content__amount-votes-and-calendar-votes {
        gap: 6px;
    }
}
@media (max-width: 992px) {
    .main-content__title {
        text-align: left;
        font-size: 26px;
        padding-bottom: 24px;
        font-weight: 500;
        line-height: 131%;
    }
    .main-content__my-votes-actual {
        margin-top: 14px;
        display: flex;
        flex-direction: column;
        gap: 10px;
    }
    .main-content__amount-votes-and-calendar-votes {
        flex-direction: column-reverse;
        gap: 16px;
    }
    .main-content__empty-states-wrapper{
        flex-direction: column;
        padding-bottom: 42px;
    }
    .main-content__empty-states-myvotes-block {
        display: block;
        height: 60px;
    }
    .main-content__empty-states-wrapper {
        gap: 16px;
    }
    .main-content__empty-states-qr-code {
        display: block;
    }
    .gistogramma-and-observer-cryptoveche {
        flex-direction: column;
    }
    .main-content__empty-states-component{order: 0}
    .main-content__empty-states-observer-amount{order: 1}
}
