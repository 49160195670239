/*------Основной контейнер--------------------------------------------------------------------------------------------*/
.title-for-votes-call-profile__title {
    font-size: 32px;
    line-height: 40px;
    font-weight: 600;
    color: rgba(54, 59, 77, 0.9);
}
/*------Контейнер для div c инф о странице и стрелкой-----------------------------------------------------------------*/
.title-for-votes-call-profile__page-info {
    color: rgba(54, 59, 77, 0.9);
    padding-bottom: 20px;
}
.page-info__info-and-row {
    display: flex;
    flex-direction: row;
    gap: 16px;
    opacity: 0.4;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
}
.page-info__link-back-row {
    display: none;
}
.page-info__link-back-row img {
    padding-top: 4px;
}
.info-and-row__row-link {
    text-decoration: none;
    color: #363B4D;
}
.info-and-row__link-back-row {
    text-decoration: none;
    color: #363B4D;
    opacity: 0.4;
}
/*------Адаптивная версия(660px)---------------------------------------------------------------------------------------*/
@media (max-width: 992px) {
    .title-for-votes-call-profile__title {
        font-size: 26px;
    }
    .title-for-votes-call-profile__page-info {
        padding-bottom: 17px;
    }
}
@media (max-width: 420px) {
    .title-for-votes-call-profile__title {
         font-size: 26px;
         font-weight: 500;
         line-height: 131%;
    }
    .page-info__link-back-row {
        display: flex;
        flex-direction: row;
        gap: 11px;
        font-size: 15px;
        font-weight: 400;
        line-height: 121%;
        color:  rgba(54, 59, 77, 0.4)
    }
    .page-info__info-and-row {
        display: none;
    }
    .title-for-votes-call-profile__page-info {
        padding-bottom: 12px;
        padding-top: 12px;
    }
}
