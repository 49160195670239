/*------Контейнер для блока------------------------------------------------------------------------------------------*/
.count-block {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 15px;
    padding-bottom: 56px;
    overflow: hidden;
}
.count-block::-webkit-scrollbar {
    width: 6px;
    height: 6px;/* ширина всей полосы прокрутки */
}
.count-block::-webkit-scrollbar-track {
    background: rgba(54, 59, 77, 0.1);        /* цвет зоны отслеживания */
}
.count-block::-webkit-scrollbar-thumb {
    background-color: darkgray;    /* цвет бегунка */
    border-radius: 20px;       /* округлось бегунка */
    /* отступ вокруг бегунка */
}
.count-block__style {
    display: flex;
    gap: 4px;
    position: relative;
    width: 320px;
    height: 128px;
    border-radius: 16px;
    flex-direction: column;
    font-size: 16px;
    font-weight: 500;
    line-height: 127%;
    color: #FFFFFF;
    padding: 36px 56px 36px 40px;
    background-image: url("../../img/CountBlock_image.svg");
    background-repeat: no-repeat;
    background-position: right center;
    box-shadow: 0px 6px 4px 0px rgba(69, 105, 255, 0.15);
}
.count-block__style h3 {
    font-size: 30px;
    font-weight: 600;
    line-height: 107%;
    @media (max-width: 1280px) {
        font-size: 20px;
    }
}
/*------Позиционирование картинки в блоке-----------------------------------------------------------------------------*/
.count-block__style-image {
    position: absolute;
    width: 130px;
    height: 128px;
    top: 0;
    right: 0;
    object-fit: cover;
}
._blue {
    background-color: #0084FE;
}
._orange {
    background-color: #FE6C36
}
._green {
    background-color: #5EE67B;
}
._yellow {
    background-color: #F9C521;
}
/*------Адаптивная версия(1170px)-------------------------------------------------------------------------------------*/
@media (max-width: 1280px) {
    .count-block {
        padding-bottom: 24px;
    }
    .count-block__style {
        font-size: 14px;
        height: 90px;
        padding: 20px 56px 20px 40px;
    }

}
/*------Адаптивная версия(1032px)-------------------------------------------------------------------------------------*/
@media (max-width: 1024px) {
    .count-block__style {
        height: 96px;
        padding: 0 15px;
        justify-content: center;
        /*align-items: center;*/
        flex-wrap: nowrap;
    }

}
/*------Адаптивная версия(800px)--------------------------------------------------------------------------------------*/
@media (max-width: 767px) {
    .count-block {
        position: relative;
        display: flex;
        gap: 8px;
        overflow: auto;
    }
    .count-block__style {
        display: block;
        padding: 24px 16px;
        height: 96px;
        width: 152px;
    }

    .count-block__style span {
        font-size: 14px;
        font-weight: 600;
    }
    .count-block__style-image {
        width: 75px;
        height: 96px;
    }
}