.status-day-start-reg-color__red-color-item {
    text-align: center;
    border-radius: 24px;
    font-size: 12px;
    line-height: 20px;
    padding: 2px 12px 2px 12px;
    background-color: rgba(255, 73, 112, 0.2);
    color: #FF4970;
    margin: 0 auto;
}
