/*------Основной стиль модального окна--------------------------------------------------------------------------------*/
.auth-set-pass-modal {
    height: 100vh;
    width: 100vw;
    background-color: rgba(0,0,0,0.4);
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    pointer-events: none;
    transition: 0.5s;
    z-index: 10;
}
/*-----Активация модального окна при клике------------------------------------------------------------------------------*/
.auth-set-pass-modal.active {
    opacity: 1;
    transform: scale(1);
    pointer-events: all;
}
/*------Стилизация самого модального окна-----------------------------------------------------------------------------*/
.auth-set-pass-modal__content {
    display: flex;
    flex-direction: column;
    gap: 40px;
    padding: 48px 40px 48px 40px;
    width: 548px;
    height: 222px;
    border-radius: 8px;
    background-color: #FFFFFF;
    position: relative;
}

/*------Стилизация сообщения модального окна-------------------------------------------------------------------------*/
.auth-set-pass-modal__content h4 {
    font-weight: 500;
    font-size: 22px;
    line-height: 30px;
    color: rgba(54, 59, 77, 0.9);
}
/*------Стилизация кнопки модального окна-----------------------------------------------------------------------------*/
.auth-set-pass-modal__content button {
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    width: 217px;
    height: 56px;
    border-radius: 4px;
    color: #FFFFFF;
    background-color: #0084FE;
    cursor: pointer;
}
.title__checkmark-set-pass-modal {
    display: none;
    position: absolute;
    top: 85px;
    left: 16px;
    width: 18px;
    height: 14px;
}
/*------ Адаптивная версия--------------------------------------------------------------------------------------------*/
@media (max-width: 992px) {
    .auth-set-pass-modal {
        display: flex;
        height: 667px;
        top: 238px;
        pointer-events: none;
        background-color: inherit;
    }
    .auth-set-pass-modal__content {
        gap: 24px;
        height: 202px;
        width: 380px;
        padding: 24px 16px 26px 16px;
    }
    .content__title {
        display: flex;
    }
    .auth-set-pass-modal__content h4 {
        font-size: 16px;
        line-height: 24px;
        font-weight: 600;
        color: rgba(54, 59, 77, 0.9);
        padding-left: 30px;

    }
    .auth-set-pass-modal__content button {
        width: 343px;
        height: 48px;
        font-size: 16px;
        font-weight: 600;
    }
    .title__checkmark-set-pass-modal {
        display: inherit;
    }

}