/*------Контейнер для страницы авторизации-------------------------------------------------------------------------*/
.wrapper-auth-forget {
  top: 0;
  left: 0;
  position: fixed;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
@media (max-width: 992px) {
  .wrapper-auth-forget {
    overflow-y: auto;
  }
}
.container-auth-forget {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 18px;
}

.main-bloсk-forget {
  display: flex;
  flex-direction: row;
  position: relative;
  width: 1128px;
}
@media (max-width: 767px) {
  .main-bloсk-forget {
    flex-direction: column;
    min-height: 100%;
    height: 100vh;
    width: 100%;
  }
}

a {
  text-decoration: underline;
  cursor: pointer;
}

.auth-forget__title {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 32px;
}

.auth-forget__title-name {
  color: rgba(54, 59, 77, 0.9);
  text-align: center;
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: 125%;
}

.auth-forget__email-toggle {
  cursor: pointer;
  text-decoration: underline;
}

.auth-forget__email-toggle.active {
  color: #0084fe;
}

.auth-forget__email-toggle:hover {
  color: #0084fe;
}

.auth-forget__phone-toggle {
  cursor: pointer;
  text-decoration: underline;
}

.auth-forget__phone-toggle.active {
  color: #0084fe;
}

.auth-forget__phone-toggle:hover {
  color: #0084fe;
}

/*------Cтилизация к описанию формы e-mail---------------------------------------------------------------------------*/
.auth__description {
  font-size: 18px;
  font-weight: 400;
  line-height: 28px;
}

/*------Стрелка для возврата на страницу авторизации------------------------------------------------------------------*/
.auth__link-row-icon {
  width: 24px;
  height: 16px;
}

.main-block__auth.active {
  display: none;
}

/*-----Стилизация блока с input email --------------------------------------------------------------------------------*/
.form__e-mail {
  display: flex;
  flex-direction: column;
  position: relative;
}

/*------Стилизация поля ввода email-----------------------------------------------------------------------------------*/
.form__e-mail input {
  border-radius: 8px;
  width: 100%;
  height: 56px;
  padding: 5px 24px;
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;
  outline: none;
  border: 1px solid rgba(54, 59, 77, 0.6);
}

.form__e-mail-error {
  font-size: 12px;
  line-height: 14px;
  font-weight: 400;
  color: #ff4970;
  opacity: 0.9;
  position: absolute;
  left: 0;
  bottom: -20px;
}

/*------Стилизация кнопки Отправить-----------------------------------------------------------------------------------*/
.auth__button button {
  width: 175px;
  height: 56px;
  border-radius: 4px;
  color: #ffffff;
  font-size: 18px;
  font-weight: 500;
  line-height: 133%;
  background-color: #0084fe;
  cursor: pointer;
}

.auth__button button:hover {
  background-color: #ffffff;
  color: #0084fe;
  border: 1px #0084fe solid;
}

/*------Стилизация кнопки Готово---------------------------------------------------------------------------------------*/

@media (max-width: 1280px) {
  .main-bloсk-forget {
    max-width: 800px;
  }

  .auth-forget__title {
    gap: 24px;
  }

  .auth-forget__title-name {
    font-size: 22px;
  }
}
@media (max-width: 767px) {
  .container-auth-forget {
    width: 100%;
    padding: 0;
    max-width: 440px;
  }
}
/*------Адаптивная версия(800px)--------------------------------------------------------------------------------------*/
@media (max-width: 992px) {
  .auth__link-row-icon {
    top: 35px;
    z-index: 5;
  }

  .auth__description {
    font-size: 16px;
    line-height: 26px;
  }

  .form__e-mail input {
    width: 100%;
    height: 48px;
  }

  .auth__button button {
    height: 48px;
    width: 100%;
    background-color: #0084fe;
    padding: 12px 145px 12px 145px;
    color: #ffffff;
    font-size: 16px;
    line-height: 24px;
    border-radius: 4px;
    font-weight: 500;
    cursor: pointer;
  }

  .button-ready-mobile {
    display: inherit;
  }
}
/*------Адаптивная версия(375px)--------------------------------------------------------------------------------------*/
