/*------Общий стиль для всего блока----------------------------------------------------------------------------------*/
.diagramm-container {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 800px;
}
.diagramm-container__info {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

}
.diagramm-container__info h3 {
    font-size: 60px;
    line-height: 70px;
    color: #4ED4A9;
    font-weight: 600;

}
.diagramm-container__info p {
    font-size: 14px;
    line-height: 94%;
    color: rgba(54, 59, 77, 0.6);
    font-weight: 400;
}
/*------Стили для кругов диаграммы-----------------------------------------------------------------------------------*/
.diagramm-circle {
      width: 600px;
      height: 370px;
}
/*------При-увеличении dashoffset у green, надо уменьшать dasharray у желтого-------------------------------------------*/
.circle__style {
    fill: none;
    stroke-width: 5;
}

/*------Стили для стрелочек-кнопок----------------------------------------------------------------------------------*/
.diagramm-container__row-button-right {
    position: absolute;
    right: 0;
    z-index: 1;
}

.diagramm-container__row-button-right_hide {
    display: none;
}

.diagramm-container__row-button-left {
    transform: rotate(180deg);
    position: absolute;
    left: 0;
    z-index: 1;
}

.diagramm-container__row-button-left_hide {
    display: none;
}
/*------Адаптивная версия(830px)--------------------------------------------------------------------------------------*/
@media (max-width: 1024px) {
    .diagramm-container {
        justify-content: center;
    }
    .diagramm-circle {
        height: 290px;
    }
    .diagramm-container__info {

    }
    .diagramm-container__info h3 {
        font-size: 40px;
        line-height: 50px;
    }
    .diagramm-container__info p {
        font-size: 10px;
        Line-height: 132%
    }
}
/*------Адаптивная версия(420px)--------------------------------------------------------------------------------------*/
@media (max-width: 470px) {
    .diagramm-container__info {

    }
    .diagramm-container__info h3 {
        font-size: 38px;
        line-height: 45px;
    }
    .diagramm-container__info p {
        font-size: 10px;
    }
}
@media (max-width: 420px) {
    .diagramm-circle {
        height: 240px;
    }
    .diagramm-container__info {
    }
}

