.start-reg__data {
    display: flex;
    align-items: center;
    gap: 9px;
    color: #363B4D;
}
.data__calendar-icon {
    width: 12px;
    height: 13px;
}
.data__watch-icon {
    width: 13px;
    height: 13px;
}
@media (max-width: 992px) {
    .start-reg__data {
        font-size: 10px;
    }
}
@media (max-width: 420px) {
    .start-reg__data {
        font-size: 13px;
        gap: 6px;
    }
}
