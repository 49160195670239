.empty-states-component_wrapper {
    display: flex;
    flex-direction: column;
    gap: 24px;
    font-size: 18px;
    line-height: 28px;
    font-weight: 400;
    color: rgba(54, 59, 77, 0.9);
    position: relative;
    background-color: #FFFFFF;
    border-radius: 24px;
    padding: 32px 32px 39px 32px;
    width: 100%;
    box-shadow: 0 3px 16px 0 rgba(54, 58, 72, 0.08);
}
.empty-states-component__title {
    display: flex;
    font-size: 24px;
    line-height: 32px;
    font-weight: 600;
    color: rgba(54, 59, 77, 0.9);
    gap: 12px;
}

/*------Адаптивная версия(830px)--------------------------------------------------------------------------------------*/

@media (max-width: 1280px) {
    .empty-states-component_wrapper {
        font-size: 16px;
        line-height: 134%;
        padding: 16px 16px;
    }
    .empty-states-component__title {
        font-size: 21px;
    }

}
/*------Адаптивная версия(830px)--------------------------------------------------------------------------------------*/
@media (max-width: 1024px) {
    .empty-states-component_wrapper {
        font-size: 16px;
        line-height: 134%;
    }
    .empty-states-component__title {
        font-size: 21px;
        line-height: 29px;
        font-weight: 500;
    }
}
@media (max-width: 992px) {
    .empty-states-component_wrapper {
        padding: 16px 16px 23px 16px;
    }
}
@media (max-width: 992px) {
    .empty-states-component_wrapper {
        max-width: 100%;
        padding: 16px 16px 23px 16px;
    }
}