/*------Основной контейнер--------------------------------------------------------------------------------------------*/
.details-votes-page-result-card-graph__wrapper {
    display: flex;
    flex-direction: row;
    gap: 72px;
    height: 352px;
    margin-bottom: 34px;
}
/*------Стилизация для списка названий колонок------------------------------------------------------------------------*/
.details-votes-page-result-card-graph__column-list {
    display: flex;
    gap: 12px;
    flex-direction: column;
    justify-content: center;
    overflow: auto;
    max-height: 400px;
}
.details-votes-page-result-card-graph__column-list::-webkit-scrollbar {
    width: 8px;
    height: 8px;/* ширина всей полосы прокрутки */
}

.details-votes-page-result-card-graph__column-list::-webkit-scrollbar-track {
    background: rgba(54, 59, 77, 0.1);        /* цвет зоны отслеживания */
}

.details-votes-page-result-card-graph__column-list::-webkit-scrollbar-thumb {
    background-color: darkgray;    /* цвет бегунка */
    border-radius: 20px;       /* округлось бегунка */
    /* отступ вокруг бегунка */
}

/*-----Адаптивная версия---------------------------------------------------------------------------------------------*/
@media (max-width: 992px) {
    .details-votes-page-result-card-graph__wrapper {
        gap: 42px;
    }
}
@media (max-width: 992px) {
    .details-votes-page-result-card-graph__wrapper {
        flex-direction: column;
        gap: 32px;
        height: 100%;
        margin-bottom: 0;
    }
    .details-votes-page-result-card-graph__column-list {
        gap: 8px;
    }
}