/*-----Основной контейнер---------------------------------------------------------------------------------------------*/
.my-profile-page__wrapper {
    padding-bottom: 112px;
    display: flex;
    flex-direction: column;
    gap: 32px;
}
.my-profile-page__main-content {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-auto-rows: auto;
    grid-auto-flow: dense;
    column-gap: 16px;
    row-gap: 16px;
    align-items: start;
}
.main-content__grid-item_1 {
    grid-column: span 1;
    grid-row: span 2;
}
.main-content__grid-item_2 {
    grid-column: span 1;
    grid-row: span 1;
}
.main-content__grid-item_3 {
    grid-column: span 1;
    grid-row: span 2;
    max-height: 664px;
}
.main-content__grid-item_4 {
    grid-column: span 1;
    grid-row: span 1;
}
/*-----Cтилизация кнопочки---------------------------------------------------------------------------------------------*/
.my-profile-page__save-change-button {
    max-width: 224px;
    padding: 12px 24px 12px 24px;
    background: rgba(54, 59, 77, 0.08);
    border-radius: 4px;
    font-size: 16px;
    font-weight: 500;
    line-height: 124%;
    color: rgba(54, 59, 77, 0.35);
    /*cursor: pointer;*/
    height: 48px;
}
/*-----Cтилизация кнопочки Выйти из профиля---------------------------------------------------------------------------*/
.my-profile-page__exit-button-profile {
    display: none;
    width: 100%;
    min-height: 40px;
    background: #FF4970;
    padding: 8px 108px 8px 108px;
    border-radius: 4px;
    color: #FFFFFF;
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;
    cursor: pointer;
}
/*------Адаптивная версия---------------------------------------------------------------------------------------------*/
@media (max-width: 1024px) {
    .my-profile-page__save-change-button {
        height: 40px;
        font-size: 14px;
    }
    .main-content__grid-item_3 {
        grid-column: span 1;
        grid-row: span 2;
        max-height: 500px;
    }
}
@media (max-width: 767px) {
    .my-profile-page__main-content {
        display: flex;
        flex-direction: column;
        align-items: stretch;
        gap: 7px;
        margin-bottom: 48px;
    }
    .main-content__grid-item_1{order: 0}
    .main-content__grid-item_2{order: 3}
    .main-content__grid-item_3{order: 4}
    .main-content__grid-item_4{order: 1}
}
@media (max-width: 420px) {
    .my-profile-page__wrapper {
        gap: 24px;
        padding-bottom: 48px;
    }
    .my-profile-page__exit-button-profile {
      display: block;
    }
    .my-profile-page__save-change-button {
        max-width: 100%;
        padding: 8px 24px;
        background: #0084FE;
        font-size: 14px;
        font-weight: 500;
        color: #FFFFFF;
        cursor: pointer;
        min-height: 40px;
    }

}