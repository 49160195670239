.call-voting-name-columns__wrapper {
    width: 140px;
    word-break: break-word;
    text-align: center;
}
.call-voting-name-columns__wrapper-active-view-list {
    display: flex;
    gap: 8px;
}
/*---------Адаптивная версия-----------------------------------------------------------------------------------------*/
@media (max-width: 992px) {
    .call-voting-name-columns__wrapper {
        width: 117px;
    }
}